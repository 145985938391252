import { useState } from 'react'
import { JSONFormPayload } from '../../apiTypes'
import { usePromptContext } from '../../context/PromptContext'
import { promptDeletionEndpoint, promptDetailEndpoint } from '../../endpoints'
import { usePut } from '../../hooks/usePut'
import BotConfigButton from '../bot-config/BotConfigButton'
import PromptForm from './PromptForm'
import { PromptDetail, PromptLibrary } from './types'
import { useDelete } from '../../hooks/useDelete'
import { emphasize, styled } from '@mui/material'
import DeleteTooltip from '../common/tooltips/DeleteTooltip'
import { canEditPrompt } from './permissions'
import Breadcrumb from '../common/Breadcrumb'
import CenteredContainer from '../common/CenteredContainer'
import ErrorCard from '../common/ErrorCard'
import { noPromptEditPermission } from '../../utils/userMessages'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
})

export const StyledDeleteButton = styled(BotConfigButton)(({ theme }) => ({
    backgroundColor: theme.palette.error.dark,

    '&:hover': {
        backgroundColor: emphasize(theme.palette.error.dark, 0.1),
    },
}))

const DeleteWrapper = styled('div')({
    position: 'relative',
    left: '70%',
    width: 0,
    height: 0,
    bottom: '40px',
})

interface EditPromptProps {
    library: PromptLibrary
    prompt: PromptDetail
}

const EditPrompt = ({ library, prompt }: EditPromptProps) => {
    const { refreshLibraries, refreshPromptList, onSelectPrompt, onEditPrompt } = usePromptContext()

    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)

    const [updatePrompt] = usePut<null, JSONFormPayload>(promptDetailEndpoint(library.id, prompt.id))
    const [deletePrompt] = useDelete(promptDeletionEndpoint(library.id))

    const currentPromptFormValue = {
        library: library.id,
        name: prompt.name,
        description: prompt.description,
        message: prompt.messageList.map(msg => msg.content).join('\n\n'),
        tags: prompt.tagList.map(tag => tag.id),
    }

    const handleSubmit = async (prompt: JSONFormPayload) => {
        const updatedPrompt = {
            prompt: prompt,
            variables: [],
        }

        const success = await updatePrompt(null, updatedPrompt)

        if (success) {
            refreshLibraries()
            refreshPromptList()
            onSelectPrompt(null)
            onEditPrompt(false)
        }
    }

    const handleGoBack = () => {
        onSelectPrompt(null)
        onEditPrompt(false)
    }

    const handlePromptDelete = async () => {
        const success = await deletePrompt(prompt.id)

        if (success) {
            refreshPromptList()
            onSelectPrompt(null)
            onEditPrompt(false)
        }
    }

    if (!canEditPrompt(library.userRole)) {
        return (
            <>
                <Breadcrumb onClick={handleGoBack} label='Back to Library' />
                <CenteredContainer>
                    <ErrorCard>{noPromptEditPermission}</ErrorCard>
                </CenteredContainer>
            </>
        )
    }

    return (
        <Container>
            <PromptForm onSubmit={handleSubmit} initialFormValue={currentPromptFormValue} library={library} onBack={handleGoBack} />
            <DeleteWrapper>
                <DeleteTooltip
                    id={prompt.id}
                    targetName='prompt'
                    description='This Prompt will be permanently deleted.'
                    onDelete={handlePromptDelete}
                    open={deleteTooltipOpen}
                    handleClose={() => setDeleteTooltipOpen(false)}
                    placement='top'
                >
                    <StyledDeleteButton onClick={() => setDeleteTooltipOpen(true)} type='button' aria-label='delete prompt'>
                        Delete
                    </StyledDeleteButton>
                </DeleteTooltip>
            </DeleteWrapper>
        </Container>
    )
}

export default EditPrompt
