import { Autocomplete, Box, CSSObject, styled, TextField } from '@mui/material'
import { useReportContext } from '../../context/ReportContext'
import { useState } from 'react'
import { REPORT_CONFIG_FORM_ID } from './ReportConfig'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
})

const widthStyles: CSSObject = { maxWidth: 300, width: '100%', minWidth: 200 }

const languageOptions = [
    'Afrikaans',
    'Albanian',
    'Arabic',
    'Armenian',
    'Basque',
    'Bengali',
    'Bulgarian',
    'Catalan',
    'Cambodian',
    'Chinese (Mandarin)',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Fijian',
    'Finnish',
    'French',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Korean',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Samoan',
    'Serbian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swahili',
    'Swedish',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tongan',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa',
]

const TranslationConfig = () => {
    const { generateReport } = useReportContext()

    const [language, setLanguage] = useState<string | null>(null)
    const [instructions, setInstructions] = useState('')

    return (
        <Container
            component='form'
            id={REPORT_CONFIG_FORM_ID}
            onSubmit={event => {
                event.preventDefault()

                if (language) {
                    generateReport({ targetLanguage: language, additionalInstructions: instructions })
                }
            }}
        >
            <Autocomplete
                sx={widthStyles}
                options={languageOptions}
                value={language}
                onChange={(_, value) => setLanguage(value)}
                renderInput={params => <TextField label='Select a language' required {...params} />}
                freeSolo
                autoSelect
                selectOnFocus
                handleHomeEndKeys
            />
            <TextField
                label='Additional Instructions'
                value={instructions}
                onChange={({ currentTarget: { value } }) => setInstructions(value)}
                sx={{ ...widthStyles, marginTop: '1rem' }}
                multiline
            />
        </Container>
    )
}

export default TranslationConfig
