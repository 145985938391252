import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography, styled } from '@mui/material'
import { captureMessage } from '@sentry/react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { IssueReportPayload } from '../apiTypes'
import { useChatContext } from '../context/ChatContext'
import { useUserContext } from '../context/UserContext'
import { issueReportEndpoint } from '../endpoints'
import { usePost } from '../hooks/usePost'
import { feedbackFormMsg } from '../utils/userMessages'
import KnownIssues from './KnownIssues'
import Button from './common/Button'
import Modal, { ModalProps } from './common/Modal'
import { mediaQueries } from './common/styles'

const StyledModal = styled(Modal)`
    width: 80%;
    max-width: 600px;

    @media ${mediaQueries.mobile} {
        width: 60%;
        padding: 24px 48px;
        max-width: 320px;
    }
`

const FlexContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: 24,
}))

const StyledTextField = styled(TextField)`
    & .MuiInputBase-input {
        max-height: 200px;
        min-height: 50px;
    }
`

const StyledRadioGroup = styled(RadioGroup)(() => ({
    gap: 56,
}))

const AllowAccessWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -20,
    gap: 16,
}))

const ReportIssueModal = ({ show, onClose }: ModalProps) => {
    const { userEmail } = useUserContext()
    const { currentChat } = useChatContext()

    const [submitIssueReport] = usePost<IssueReportPayload, boolean>(issueReportEndpoint)

    const [allowAccess, setAllowAccess] = useState(false)
    const [reportType, setReportType] = useState<'tool' | 'general'>('general')
    const [details, setDetails] = useState('')

    const chatToReportId = currentChat ? currentChat.id : null

    const handleClose = () => {
        setDetails('')
        setAllowAccess(false)
        setReportType('general')
        onClose()
    }

    const handleChangeReportType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newReportType = e.target.value === 'tool' ? 'tool' : 'general'
        setReportType(newReportType)
        if (newReportType === 'tool') {
            setAllowAccess(true)
        } else {
            setAllowAccess(false)
        }
    }

    const handleSubmit = async () => {
        const reportData = {
            userEmail: userEmail ?? '',
            details,
            conversationId: chatToReportId,
            reportType,
            url: window.location.href,
            allowAccess: allowAccess,
        }

        const response = await submitIssueReport(reportData)
        if (response) {
            toast.info(feedbackFormMsg)

            if (allowAccess) {
                captureMessage(`Session has been flagged ${chatToReportId}`)
            }
        }

        handleClose()
    }

    const sessionData = () => {
        switch (currentChat?.bot.toolType) {
            case 'chatbot':
                return "all chat messages and any documents you've uploaded"
            case 'document_skimmer':
                return "all documents you've uploaded, prompts and cell results"
            case 'report_generator':
                return "all documents you've uploaded and the resulting report(s)"
        }
    }

    return (
        <StyledModal show={show} onClose={handleClose} heading='Report an Issue'>
            <FlexContainer>
                <Typography variant='body1'>
                    You can report a general issue with the platform, or a problem with your current session.
                    <br /> <br />
                    If you are reporting a session issue, you can also opt to share its data with Springbok AI. Session data will be used to address your report
                    and stored for up to 30 days.
                </Typography>
                <KnownIssues />
                <StyledRadioGroup value={reportType} onChange={e => handleChangeReportType(e)} row>
                    <FormControlLabel
                        value='general'
                        control={<Radio aria-label='general issue' role='radio' aria-checked={reportType === 'general'} />}
                        label='General Issue'
                    />
                    <FormControlLabel
                        value='tool'
                        control={<Radio aria-label='session issue' role='radio' aria-checked={reportType === 'tool'} />}
                        label={`Session Issue ${currentChat ? '' : ' (no active session)'}`}
                        disabled={!currentChat}
                    />
                </StyledRadioGroup>
                {reportType === 'tool' && (
                    <AllowAccessWrapper>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allowAccess}
                                    onChange={e => setAllowAccess(e.target.checked)}
                                    name='allowAccess'
                                    aria-label='allowAccess'
                                    role='checkbox'
                                    aria-checked={allowAccess}
                                />
                            }
                            label='Share session data with Springbok AI'
                        />
                        <Typography variant='body1'>Session data includes: {sessionData()}.</Typography>
                        {allowAccess ? (
                            <Typography variant='body1'>
                                <b>Do not share session data</b> if it contains sensitive or confidential information or if sharing it is prohibited by your
                                firm's guidelines.
                            </Typography>
                        ) : (
                            <Typography variant='body1'>
                                If you do not wish to share session data, please include a redacted summary of the relevant content.
                            </Typography>
                        )}
                    </AllowAccessWrapper>
                )}
                <StyledTextField
                    placeholder="Please describe what you've been trying to accomplish and what went wrong"
                    value={details}
                    onChange={e => setDetails(e.target.value)}
                    multiline
                    rows={3}
                    fullWidth
                />
                <Button color='primary' onClick={handleSubmit}>
                    Submit
                </Button>
            </FlexContainer>
        </StyledModal>
    )
}

export default ReportIssueModal
