import { PromptLibraryRole } from '../../types'

const isOwnerOrHigher = (role: PromptLibraryRole) => role >= PromptLibraryRole.Owner

const isEditorOrHigher = (role: PromptLibraryRole) => role >= PromptLibraryRole.Editor

export const canExportLibrary = (isMaintainer: boolean, role: PromptLibraryRole) => isMaintainer && isOwnerOrHigher(role)

export const canShareLibrary = (isMaintainer: boolean, role: PromptLibraryRole) => isMaintainer && isEditorOrHigher(role)

export const canDeleteLibrary = isOwnerOrHigher

export const canEditLibrary = isEditorOrHigher

export const canEditPrompt = isEditorOrHigher
