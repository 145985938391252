import { toast } from 'react-toastify'
import { CreateOrUpdateGenericReportPayload, ReportConfigPayload } from '../apiTypes'
import { BaseReport } from '../components/report/types'
import { FileWithMetadata } from '../types'
import { getFolderDepth, hasSubfolders, isInFolder, oneSubfolderExceedsFileLimit } from './documents'

export const getManyToOneReportPayload = <TReport extends BaseReport>(
    folders: string[],
    reports: TReport[] | null | undefined,
    files: FileWithMetadata[],
    config: ReportConfigPayload = {}
) =>
    folders.reduce(
        (payload: CreateOrUpdateGenericReportPayload, folder) => {
            const isInCurrentFolder = isInFolder(folder)

            const report = reports?.find(r => r.name === folder)
            const folderFileIds = files.filter(isInCurrentFolder).map(f => f.id)

            if (!folderFileIds.length) {
                return payload
            }

            payload.reportInputs.push({
                reportId: report?.id,
                name: report?.name ?? folder,
                sourceDocumentIds: folderFileIds,
            })

            return payload
        },
        { reportInputs: [], sessionConfig: config }
    )

export const getOneToOneReportPayload = (files: FileWithMetadata[], config: ReportConfigPayload = {}) =>
    files.reduce(
        (payload: CreateOrUpdateGenericReportPayload, file) => {
            payload.reportInputs.push({
                name: '',
                sourceDocumentIds: [file.id],
            })

            return payload
        },
        { reportInputs: [], sessionConfig: config }
    )

/**files that exist at the top level or anything other than the first subfolder level */
export const getUnexpectedFileIds = (files: FileWithMetadata[]) => files.filter(file => getFolderDepth(file.folderName) !== 1).map(f => f.id)

const filterUnexpectedFiles = (files: FileWithMetadata[]) => {
    const unexpectedIds = getUnexpectedFileIds(files)
    if (unexpectedIds.length) {
        toast.warn(`There are ${unexpectedIds.length} files in this upload that do not fall into the accepted folder structure - they will be ignored.`)
    }

    return files.filter(f => !unexpectedIds.includes(f.id))
}

export const reportFolderFileLimit = 25
export const tryFilterReportFiles = (files: FileWithMetadata[]) => {
    const isBulk = hasSubfolders(files)

    const filteredFiles = isBulk ? filterUnexpectedFiles(files) : files

    if ((isBulk && oneSubfolderExceedsFileLimit(reportFolderFileLimit)(files)) || (!isBulk && files.length > reportFolderFileLimit)) {
        toast.warn('A report folder cannot contain more than 25 documents. Please review before uploading.')
        return false
    }

    return filteredFiles
}
