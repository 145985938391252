import { styled, Typography } from '@mui/material'
import CardList from '../common/CardList'
import { Report } from './types'
import ReportCard from './ReportCard'
import { cardListStyles } from './styles'

const Container = styled('div')({
    ...cardListStyles,
    display: 'flex',
    flexDirection: 'column',
    margin: '40px auto 0',
    overflow: 'hidden',
})

interface ReportDisplayProps {
    className?: string
    reports: Report[]
}

const ReportDisplay = ({ className, reports }: ReportDisplayProps) => (
    <Container className={className}>
        <Typography variant='h1' sx={{ margin: '0 0 32px 8px' }}>
            Your results are ready!
        </Typography>
        <CardList>
            {reports.map(report => (
                <ReportCard key={report.id} report={report} />
            ))}
        </CardList>
    </Container>
)

export default ReportDisplay
