import { PromptResult, ResultState } from './components/doc-skimmer/utils/types'
import { Bot, ChatStatus, Chunk, DocumentName, DocumentState, Dollar, Message, UUID, Url } from './types'

export interface SocketInfoResponse {
    protocol: 'ws://' | 'wss://'
    endpoint: string
}

const messageTypeValues = ['bot', 'title', 'error', 'state', 'document_state', 'notification', 'bot_chunk', 'progress', 'cell_state', 'cell'] as const
type MessageType = (typeof messageTypeValues)[number]

type ChatMessage = Message & {
    type: (typeof messageTypeValues)[0]
}

interface TitleMessage {
    type: (typeof messageTypeValues)[1]
    chatId: UUID
    title: string
}

interface ErrorMessage {
    type: (typeof messageTypeValues)[2]
    status: 500 | 429 | 422
    userMessage?: string
}

interface StateMessage {
    type: (typeof messageTypeValues)[3]
    chatId: UUID
    state: ChatStatus
}

interface DocumentStateMessage {
    type: (typeof messageTypeValues)[4]
    documentId: UUID
    chatId: UUID
    state: DocumentState
}

export interface NotificationMessage {
    type: (typeof messageTypeValues)[5]
    text: string
    chatId?: UUID
}

interface ChunkMessage extends Chunk {
    type: (typeof messageTypeValues)[6]
}

interface ProgressMessage {
    type: (typeof messageTypeValues)[7]
    currentStatus: string
    chatId: UUID
}

interface CellStateMessage {
    type: (typeof messageTypeValues)[8]
    chatId: UUID
    promptId: UUID
    documentId: UUID
    state: ResultState
}

interface CellMessage extends Omit<CellStateMessage, 'type'> {
    type: (typeof messageTypeValues)[9]
    promptDigest: string
    text: string
}

export type IncomingSocketMessage =
    | ChatMessage
    | TitleMessage
    | ErrorMessage
    | StateMessage
    | DocumentStateMessage
    | NotificationMessage
    | ChunkMessage
    | ProgressMessage
    | CellStateMessage
    | CellMessage

export const isIncomingSocketMessage = (value: unknown): value is IncomingSocketMessage =>
    value !== null && typeof value === 'object' && 'type' in value && messageTypeValues.includes(value.type as MessageType)

interface BaseMessagePayload {
    id: UUID
    chatId: UUID
    type: 'user' | 'retry'
}

interface TextMessagePayload extends BaseMessagePayload {
    type: 'user'
    text: string
}

interface DocumentMessagePayload extends BaseMessagePayload {
    type: 'user'
    text?: string
    documentIds: UUID[]
}

interface RetryMessagePayload extends BaseMessagePayload {
    type: 'retry'
}

export type MessagePayload = TextMessagePayload | DocumentMessagePayload | RetryMessagePayload

export interface CreateChatPayload {
    botId: UUID
}

export type CreateChatResponse = UUID

export interface UpdateChatTitleParams extends Record<string, string> {
    title: string
}

export interface DocumentUploadUrlsPayload {
    id: UUID
    parts: number
    name: string
}

export interface UploadPart {
    partNumber: number
    presignedUrl: Url
}

export type CloudProvider = 'aws' | 'azure'

export interface DocumentUploadUrlsResponse {
    key: string
    uploadId: string
    cloudProvider: CloudProvider
    parts: UploadPart[]
}

export interface DocumentPart {
    partNumber: number
    eTag: string
}

export interface DocumentFinalisePayload {
    key: string
    uploadId: string
    parts: DocumentPart[]
    name: DocumentName
}

interface CreateGenericReportPayload {
    name: string
    sourceDocumentIds: UUID[]
}

interface UpdateGenericReportPayload extends CreateGenericReportPayload {
    reportId: UUID
}

export interface TranslationConfigPayload {
    targetLanguage: string
    additionalInstructions: string
}

export type ReportConfigPayload = TranslationConfigPayload | Record<string, unknown>

export interface CreateOrUpdateGenericReportPayload {
    reportInputs: (CreateGenericReportPayload | UpdateGenericReportPayload)[]
    sessionConfig: ReportConfigPayload
}

export interface JSONFormResponse {
    schema: Record<string, unknown>
    uiSchema: Record<string, unknown>
    values: Record<string, unknown>
}

export type JSONFormPayload = Record<string, unknown>

interface ReportFieldSummary {
    id: UUID
    name: string
    tags: string[]
}

export type ReportFieldsListResponse = ReportFieldSummary[]

export interface ReportFieldsListPayload {
    orderedFieldIds: UUID[]
    deletedFieldIds: UUID[]
}

export interface Template {
    id: UUID
    name: string
}

export interface CreateBotPayload {
    className: string
    name: string
    description: string
}

export type CreateBotResponse = Bot

export interface NewBotOption {
    className: string
    name: string
    description: string
}

export interface SkimmerPayload {
    costCap: Dollar
    prompts: Omit<PromptResult, 'results'>[]
}

export interface WarningResponse {
    warning?: string
}

export interface UserBotPermissionsResponse {
    botId: UUID
    createdById: UUID | null
    hasTenantPermission: boolean
    userPermissions: UUID[]
}

export interface IssueReportPayload {
    userEmail: string
    details: string
    conversationId: UUID | null
    reportType: 'tool' | 'general'
    url: string
    allowAccess: boolean
}
