import { toast } from 'react-toastify'
import { environmentVariables } from '../env'
import { FileWithMetadata, isDefined } from '../types'
import { generateId } from './objectUtils'

// Note that .json files are supported for re-import of OCR output, but this functionality is currently not advertised to the end users
// Because there is no way for the end user to obtain the json file in question
const allowedMimeTypes = [
    'application/pdf',
    'text/plain',
    'text/markdown',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/json',
]
export const allowedFileExtensions = ['pdf', 'txt', 'md', 'docx']

const getExtension = (file: File) => file.name.split('.').pop()

const isTypeOfFile = (mime: string, extension: string) => (file: File) => {
    if (file.type === mime) {
        return true
    }

    return getExtension(file) === extension
}

const isDocx = isTypeOfFile('application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'docx')
const isXlsx = isTypeOfFile('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx')
const isJson = isTypeOfFile('application/json', '.json')

export const isAllowedFileType = (file: File) => {
    if (allowedMimeTypes.includes(file.type)) {
        return true
    }
    const extension = getExtension(file)
    return !!extension && allowedFileExtensions.includes(extension)
}

interface GroupedFiles {
    valid: FileWithMetadata[]
    tooLarge: File[]
    invalidFormat: File[]
    empty: File[]
}

const groupFile = (groupedFiles: GroupedFiles, file: File, folderName?: string) => {
    if (file.size > environmentVariables.documentSizeByteLimit) {
        groupedFiles.tooLarge.push(file)
    } else if (file.size === 0) {
        groupedFiles.empty.push(file)
    } else if (isAllowedFileType(file)) {
        groupedFiles.valid.push({ id: generateId(), file, folderName })
    } else {
        groupedFiles.invalidFormat.push(file)
    }

    return groupedFiles
}

const groupFileList = (files: FileList) =>
    Array.from(files).reduce((groupedFiles: GroupedFiles, file) => groupFile(groupedFiles, file), { valid: [], tooLarge: [], invalidFormat: [], empty: [] })

const groupFilesWithMetadata = (files: FileWithMetadata[]) =>
    files.reduce((groupedFiles: GroupedFiles, file) => groupFile(groupedFiles, file.file, file.folderName), {
        valid: [],
        tooLarge: [],
        invalidFormat: [],
        empty: [],
    })

export const groupFiles = (files: FileWithMetadata[] | FileList) => (Array.isArray(files) ? groupFilesWithMetadata(files) : groupFileList(files))

const isFileEntry = (entry: FileSystemEntry | null): entry is FileSystemFileEntry => entry !== null && entry.isFile && 'file' in entry

const isDirectoryEntry = (entry: FileSystemEntry | null): entry is FileSystemDirectoryEntry => entry !== null && entry.isDirectory

const getFile = (fileEntry: FileSystemFileEntry) =>
    new Promise<File>((resolve, reject) => fileEntry.file(resolve, reject)).catch(() => {
        toast.error(`Failed to attach file: ${fileEntry.fullPath}`)
        return null
    })

const getDirectory = (directoryEntry: FileSystemDirectoryEntry) =>
    new Promise<FileSystemEntry[] | null>((resolve, reject) => {
        const reader = directoryEntry.createReader()
        reader.readEntries(resolve, reject)
    }).catch(() => {
        toast.error(`Failed to process directory: ${directoryEntry.fullPath}`)
        return null
    })

const getFolderPath = (entry: FileSystemEntry | File) =>
    (entry instanceof File ? entry.webkitRelativePath : entry.fullPath)
        .split('/')
        .filter((segment, i, arr) => segment && i < arr.length - 1) //ignore blank segments (happens if starts with slash as in entry.fullPath) and last segment as it is the file name
        .join('/')

const getFlatFileList = (entries: FileSystemEntry[]): Promise<FileWithMetadata[]> =>
    entries.reduce(async (fileListPromise: Promise<FileWithMetadata[]>, entry: FileSystemEntry) => {
        if (isFileEntry(entry)) {
            const file = await getFile(entry)
            //file.webkitRelativePath is blank when uploading via drag & drop in chrome, so must use entry there
            //TODO: how to handle top level files or those in deeper subfolders
            file && (await fileListPromise).push({ id: generateId(), folderName: getFolderPath(entry), file })
        } else if (isDirectoryEntry(entry)) {
            const directory = await getDirectory(entry)

            if (directory) {
                const directoryFiles = await getFlatFileList(directory)
                return (await fileListPromise).concat(directoryFiles)
            }
        }

        return fileListPromise
    }, Promise.resolve([]))

export const convertToFileList = (items: DataTransferItemList) => {
    const filteredEntries = Array.from(items).flatMap(i => {
        const entry = i.webkitGetAsEntry()
        return entry === null ? [] : entry
    })

    return getFlatFileList(filteredEntries)
}

export const convertFileListToFilesWithMetadata = (files: FileList, folder?: string | null): FileWithMetadata[] => {
    return Array.from(files).map(file => ({ id: generateId(), folderName: folder ?? getFolderPath(file), file }))
}

export const getFolderDepth = (folder?: string) => {
    const regex = new RegExp('/', 'g')
    const matches = folder?.match(regex)
    return matches?.length ?? 0
}

/**Get the full path to all top level subfolders in the uploaded folder, in the format 'UploadedFolder/Subfolder' */
export const getAllTopLevelSubfolders = (files: FileWithMetadata[]) => {
    const uniqueFolderPaths = [...new Set(files.map(f => f.folderName).filter(isDefined))]
    const subFolderPaths = uniqueFolderPaths.filter(folder => getFolderDepth(folder) === 1).sort()
    return subFolderPaths
}

/**checks if any subfolder has more than the allowed number of files */
export const oneSubfolderExceedsFileLimit = (fileLimit: number) => (files: FileWithMetadata[]) => {
    const subFolderPaths = getAllTopLevelSubfolders(files)
    return subFolderPaths.some(folderPath => files.filter(f => f.folderName === folderPath).length > fileLimit)
}

/**checks if any of the files are in subfolders underneath the parent folder  */
export const hasSubfolders = (files: FileWithMetadata[]) => {
    return files.some(f => f.folderName?.includes('/'))
}

export const isInFolder = (folderName: string) => (file: FileWithMetadata) => file.folderName?.endsWith(folderName) ?? false

export const validateTemplateFile = (fileList: FileList | null): File | null => {
    const file = fileList?.item(0)

    if (!file) {
        return null
    }
    if (!isDocx(file)) {
        toast.warn('Templates must be in the docx format')
        return null
    }
    if (file.size === 0) {
        toast.warn(`${file.name} is empty`)
        return null
    }
    if (file.size > environmentVariables.documentSizeByteLimit) {
        toast.warn(`${file.name} exceeds the size limit of ${environmentVariables.documentSizeByteLimit / 1000}KB`)
        return null
    }

    return file
}

export const validateSkimmerImportFile = (fileList: FileList | null): File | null => {
    const file = fileList?.item(0)

    if (!file) {
        return null
    }
    if (!isXlsx(file)) {
        toast.warn('Import must be in the xlsx format')
        return null
    }
    if (file.size === 0) {
        toast.warn(`${file.name} is empty`)
        return null
    }

    return file
}

export const validateLibraryImportFile = (fileList: FileList | null): File | null => {
    const file = fileList?.item(0)

    if (!file) {
        return null
    }
    if (!isJson(file)) {
        toast.warn('Import must be in the JSON format')
        return null
    }
    if (file.size === 0) {
        toast.warn(`${file.name} is empty`)
        return null
    }

    return file
}
