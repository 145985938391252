import { CSSObject, ListItem, ListItemButton, Popover, styled, Switch, Typography } from '@mui/material'
import Input from '../common/Input'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import { useState } from 'react'
import AddIcon from '../../icons/AddIcon'
import { usePost } from '../../hooks/usePost'
import { adminUsersEndpoint, promptPermissionsEndpoint, promptPublishingEndpoint, promptSharingEndpoint, promptUnsharingEndpoint } from '../../endpoints'
import { PromptLibraryRole, User, UUID } from '../../types'
import { useGet } from '../../hooks/useGet'
import { PromptPermissionsResponse } from './types'
import { useDelete } from '../../hooks/useDelete'
import Dropdown from '../common/Dropdown'
import Loading from '../common/Loading'
import { overflowText, zIndexes } from '../common/styles'
import { useUserContext } from '../../context/UserContext'
import { canShareLibrary } from './permissions'

const Content = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 4px 4px ${theme.palette.divider}`,
    borderRadius: '10px',
    padding: '14px 20px',
    height: '500px',
    width: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
}))

const SearchDropdown = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: 'calc(100% - 42px)',
    maxHeight: '50%',
    overflow: 'auto',
    top: '54px',
    padding: '8px 0px',
    zIndex: zIndexes.dropdown,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '6px',
}))

const AccessSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    maxHeight: '350px',
    overflowY: 'auto',
})

const listItemStyles: CSSObject = {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: '10px',
    borderRadius: '8px',
    minHeight: 'fit-content',
}

const StyledListButton = styled(ListItemButton)({
    ...listItemStyles,
    '.addIcon': {
        opacity: 0,
        transition: 'opacity 0.2s',
    },
    '&:hover .addIcon': {
        opacity: 1,
    },
})

const StyledListItem = styled(ListItem)({
    ...listItemStyles,
    margin: '5px 0',
})

const Name = styled(Typography)({
    ...overflowText,
    gridArea: '1 / 1 / 2 / 2',
})

const Email = styled(Typography)({
    ...overflowText,
    gridArea: '2 / 1 / 3 / 2',
})

const actionGridArea: CSSObject = {
    gridArea: '1 / 2 / 3 / 3',
}

const PaddedText = styled(Typography)({
    paddingLeft: '16px',
})

const PublishSection = styled('section')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
})

const StyledLoading = styled(Loading)(() => ({
    position: 'absolute',
    bottom: 15,
    right: 15,
    width: 30,
    height: 30,
}))

const optionLabels: Record<PromptLibraryRole.Viewer | PromptLibraryRole.Editor | 'Remove', string> = {
    0: 'Viewer',
    1: 'Editor',
    Remove: 'Remove',
}

interface PromptSharingProps {
    libraryId: UUID
    role: PromptLibraryRole
    open: boolean
    anchorEl: HTMLElement | null
    onClose: () => void
}

const PromptSharing = ({ libraryId, role, open, anchorEl, onClose }: PromptSharingProps) => {
    const { isMaintainer } = useUserContext()
    const canShare = canShareLibrary(isMaintainer, role)

    const [searchTerm, setSearchTerm] = useState('')

    const [allUsers, usersLoading] = useGet<User[]>(isMaintainer ? adminUsersEndpoint : null)
    const [permissionsResponse, permissionsLoading, refreshPermissionsResponse] = useGet<PromptPermissionsResponse>(
        canShare ? promptPermissionsEndpoint(libraryId) : null
    )
    const [updateUser, loadingUpdateUser] = usePost<{ userId: UUID; role: PromptLibraryRole }, boolean>(promptSharingEndpoint(libraryId))
    const [removeUser, loadingRemoveUser] = useDelete(promptUnsharingEndpoint(libraryId))
    const [publish, loadingPublish] = usePost<null, boolean>(promptPublishingEndpoint(libraryId))
    const [unpublish, loadingUnpublish] = useDelete(promptPublishingEndpoint)

    // Should be a string literal union type, but Dropdown only supports string currently
    const handleUpdateUser = async (userId: UUID, role: string) => {
        let request: Promise<boolean | null> = Promise.resolve(false)

        switch (role) {
            case 'Remove': {
                request = removeUser(userId)
                break
            }
            case 'Viewer':
            case 'Editor':
                request = updateUser({ userId, role: PromptLibraryRole[role] })
                break
        }

        const success = await request
        if (success) {
            refreshPermissionsResponse()
            setSearchTerm('')
        }
    }

    const handlePublishToggle = async () => {
        if (permissionsResponse) {
            const success = await (permissionsResponse.isPublished ? unpublish(libraryId) : publish(null))
            success && refreshPermissionsResponse()
        }
    }

    const promptUsers = permissionsResponse?.users ?? []
    const filteredUsers =
        searchTerm === '' || !allUsers
            ? []
            : allUsers.filter(
                  user =>
                      (user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
                      !promptUsers.some(promptUser => promptUser.id === user.id)
              )

    const anchorPosition = anchorEl ? { top: anchorEl.getBoundingClientRect().bottom + 10, left: anchorEl.getBoundingClientRect().left } : undefined

    const loading = usersLoading || loadingUpdateUser || loadingRemoveUser || permissionsLoading || loadingPublish || loadingUnpublish

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorReference={anchorPosition ? 'anchorPosition' : 'none'}
            anchorPosition={anchorPosition}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Content>
                <Input
                    placeholder="Search for a user's name or email address"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    aria-label='search users'
                />
                {searchTerm !== '' &&
                    (filteredUsers.length > 0 ? (
                        <SearchDropdown>
                            {filteredUsers.map(user => (
                                <StyledListButton
                                    key={user.id}
                                    aria-label='add user'
                                    onClick={() => handleUpdateUser(user.id, 'Viewer')}
                                    disabled={!canShare || loadingUpdateUser}
                                >
                                    <Name>{user.name}</Name>
                                    <Email variant='caption'>{user.email}</Email>
                                    <InfoTooltip sx={actionGridArea} key={user.id} title='Add User'>
                                        <AddIcon className='addIcon' />
                                    </InfoTooltip>
                                </StyledListButton>
                            ))}
                        </SearchDropdown>
                    ) : (
                        <SearchDropdown>
                            <PaddedText variant='caption'>No users found</PaddedText>
                        </SearchDropdown>
                    ))}
                <PaddedText variant='h3'>Has Access</PaddedText>
                <AccessSection>
                    {promptUsers.map(user => (
                        <StyledListItem key={user.id}>
                            <Name>{user.name}</Name>
                            <Email variant='caption'>{user.email}</Email>
                            {user.role === PromptLibraryRole.Owner ? (
                                <Typography sx={actionGridArea}>Owner</Typography>
                            ) : (
                                <Dropdown
                                    sx={actionGridArea}
                                    selected={PromptLibraryRole[user.role]}
                                    options={new Set(Object.values(optionLabels))}
                                    onSelectionChange={selection => handleUpdateUser(user.id, selection)}
                                    multiple={false}
                                    disabled={!canShare || loading}
                                />
                            )}
                        </StyledListItem>
                    ))}
                </AccessSection>
                {permissionsResponse && (
                        <PublishSection>
                            <Typography variant='h4'>
                                {permissionsResponse.isPublished ? 'Everyone in your organisation can view' : 'Allow everyone in your organisation to view'}
                            </Typography>
                            <Switch checked={permissionsResponse.isPublished} onChange={handlePublishToggle} disabled={!canShare || loading} />
                        </PublishSection>
                )}
                {loading && <StyledLoading secondaryColor />}
            </Content>
        </Popover>
    )
}

export default PromptSharing
