import { styled, Typography } from '@mui/material'
import Loading from '../../common/Loading'

const Indicator = styled('div')({
    display: 'inline-flex',
})

const StyledLoading = styled(Loading)({
    width: '18px',
    height: '18px',
    marginRight: '10px',
})

interface ProgressUpdateProps {
    className?: string
    running: boolean
    saving: boolean
    processingCount: number
}

const ProgressUpdate = ({ className, running, saving, processingCount }: ProgressUpdateProps) => (
    <Indicator className={className}>
        <StyledLoading secondaryColor />
        {processingCount > 0 ? (
            <Typography variant='subtitle1'>{`Extracting ${processingCount} document${processingCount === 1 ? '' : 's'}...`}</Typography>
        ) : (
            <Typography variant='subtitle1'>{running ? 'Running...' : saving ? 'Saving...' : 'Loading...'}</Typography>
        )}
    </Indicator>
)

export default ProgressUpdate
