import { Context, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { CreateOrUpdateGenericReportPayload } from '../apiTypes'
import { BaseReport, Report, ReportConfig } from '../components/report/types'
import { genericReportsEndpoint } from '../endpoints'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { mockReportChat } from '../tests/mockData'
import { FileWithMetadata, ReportChat, UUID, doNothing, doNothingEventually } from '../types'
import { getAllTopLevelSubfolders, isInFolder } from '../utils/documents'
import { getManyToOneReportPayload, getOneToOneReportPayload } from '../utils/reports'
import { useChatContext } from './ChatContext'
import { useFileContext } from './FileContext'
import { useMessageContext } from './MessageContext'

export interface ReportState<TReport> {
    currentChat: ReportChat
    loading: null | 'sync' | 'uploading' | 'generating'
    onLoadingChange: (loading: null | 'sync' | 'uploading' | 'generating') => void
    reports: TReport[] | null
    failedUploadFileIds: UUID[] | null
    onUploadFailure: (failedFileIds: UUID[]) => void
    generateReport: (config?: ReportConfig) => Promise<void>
    folders: string[]
    selectedFolder: string
    onSelectedFolderChange: (folder: string) => void
    folderFiles: FileWithMetadata[] | null
    onFolderDelete: (folder: string) => void
}

export const defaultState: ReportState<BaseReport> = {
    currentChat: mockReportChat,
    loading: 'sync',
    onLoadingChange: doNothing,
    reports: null,
    failedUploadFileIds: null,
    onUploadFailure: doNothing,
    generateReport: doNothingEventually,
    folders: [''], // single file/folder uploads use empty string so they can be handled in the same way as bulk
    selectedFolder: '',
    onSelectedFolderChange: doNothing,
    folderFiles: null,
    onFolderDelete: doNothing,
}

export const ReportContext = createContext<ReportState<BaseReport>>(defaultState)

interface WithReportContextProps extends PropsWithChildren {
    currentChat: ReportChat
    getEndpoint?: (chatId: UUID) => string
}

const WithReportContextPage = <TReport extends BaseReport>({ children, currentChat, getEndpoint = genericReportsEndpoint }: WithReportContextProps) => {
    const { blockChatSwapping, unblockChatSwapping } = useChatContext()
    const { refreshRequestChatId } = useMessageContext()
    const { files, onRemove, upload } = useFileContext()
    const [reports, syncLoading, refresh] = useGet<TReport[]>(getEndpoint(currentChat.id))
    const [createReport] = usePost<CreateOrUpdateGenericReportPayload, true>(genericReportsEndpoint(currentChat.id))

    const [loading, setLoading] = useState(defaultState.loading)
    const [failedUploadFileIds, setFailedUploadFileIds] = useState<UUID[] | null>(defaultState.failedUploadFileIds)
    const [selectedFolder, setSelectedFolder] = useState(defaultState.selectedFolder)

    const [prevRefreshId, setPrevRefreshId] = useState(refreshRequestChatId)
    if (refreshRequestChatId !== prevRefreshId) {
        setPrevRefreshId(refreshRequestChatId)
        refresh()
    }

    const [prevSyncLoading, setPrevSyncLoading] = useState(syncLoading)
    if (syncLoading !== prevSyncLoading) {
        setPrevSyncLoading(syncLoading)

        if (syncLoading) {
            setLoading('sync')
        } else {
            setLoading(reports?.some(r => r.state === 'pending' || r.state === 'generating') ? 'generating' : null)
        }
    }

    const isInSelectedFolder = isInFolder(selectedFolder)
    const folderFiles = selectedFolder ? files?.filter(isInSelectedFolder) ?? [] : files

    const folders = useMemo(() => {
        if (currentChat.bot.supportsMultidocumentReport && files) {
            const allFolders = getAllTopLevelSubfolders(files).map(f => f.split('/')[1])
            return Array.from(new Set(allFolders)).sort()
        } else {
            return defaultState.folders
        }
    }, [currentChat.bot.supportsMultidocumentReport, files])

    if (folders.length && !folders.includes(selectedFolder)) {
        setSelectedFolder(folders[0])
    }

    const handleFolderDelete = (folder: string) => {
        const deleteIds = files?.filter(isInFolder(folder)).map(f => f.id)
        if (deleteIds) {
            onRemove(...deleteIds)
        }
    }

    const handleUploadFailure = (failedFileIds: UUID[]) => {
        setFailedUploadFileIds(failedFileIds)
        setLoading(null)
    }

    const handleUploadAndGenerate = async (config?: ReportConfig) => {
        if (!files?.length) {
            toast.warn('Please attach at least one file')
            return
        }
        setLoading('uploading')
        blockChatSwapping()

        const { uploadedIds, rejectedIds } = await upload()

        if (uploadedIds.length) {
            const payload = currentChat.bot.supportsMultidocumentReport
                ? getManyToOneReportPayload(folders.length > 0 ? folders : [''], reports, files, config)
                : getOneToOneReportPayload(files, config)
            const generating = await createReport(payload)
            if (generating) {
                setLoading('generating')
            } else {
                setLoading(null)
            }
        }

        if (rejectedIds.length) {
            handleUploadFailure(rejectedIds)
        }

        unblockChatSwapping()
    }

    return (
        <ReportContext.Provider
            value={{
                currentChat,
                loading,
                onLoadingChange: setLoading,
                reports: reports ?? null,
                failedUploadFileIds,
                onUploadFailure: handleUploadFailure,
                generateReport: handleUploadAndGenerate,
                folders,
                selectedFolder,
                onSelectedFolderChange: setSelectedFolder,
                folderFiles,
                onFolderDelete: handleFolderDelete,
            }}
        >
            {children}
        </ReportContext.Provider>
    )
}

const WithReportContext = ({ currentChat, ...rest }: WithReportContextProps) => (
    <WithReportContextPage key={currentChat.id} currentChat={currentChat} {...rest} />
)

const useReportContext = <TReport extends BaseReport = Report>() => useContext(ReportContext as unknown as Context<ReportState<TReport>>)

export { WithReportContext, useReportContext }
