import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useBotContext } from '../../context/BotContext'
import { useChatContext } from '../../context/ChatContext'
import { botCloneEndpoint, botInfoEndpoint, botListEndpoint } from '../../endpoints'
import { useDelete } from '../../hooks/useDelete'
import { usePost } from '../../hooks/usePost'
import { usePut } from '../../hooks/usePut'
import AddIcon from '../../icons/AddIcon'
import { Bot } from '../../types'
import { genericErrorMsg } from '../../utils/userMessages'
import Modal, { ModalProps } from '../common/Modal'
import TransparentButton from '../common/TransparentButton'
import Balancers from './Balancers'
import BotConfig from './BotConfig'
import Details from './Details'
import ShareBot from './ShareBot'
import ReportConfigGuidance from './ReportConfigGuidance'
import ReportFields from './ReportFields'
import TemplateDisplay from './TemplateDisplay'
import { TabPanel } from '../common/TabPanel'
import { styled, Tab, Tabs } from '@mui/material'

const ButtonChat = styled(TransparentButton)(() => ({
    position: 'absolute',
}))

export const FixedSizeModal = styled(Modal)(({ theme }) => ({
    width: '800px',
    height: '85vh',

    [theme.breakpoints.down('lg')]: {
        width: '650px',
    },

    [theme.breakpoints.down('md')]: {
        width: '470px',
    },
}))

const StyledTabPanel = styled(TabPanel)(() => ({
    overflowY: 'scroll',
    paddingRight: 12,
}))

interface BotConfigModalProps extends ModalProps {
    bot: Bot
}

const BotConfigModal = ({ show, onClose, bot }: BotConfigModalProps) => {
    const { editBotConfig } = useBotContext()
    const { refreshChats, createChat } = useChatContext()

    const [cloneBot, cloneLoading] = usePost<string, Bot>(botCloneEndpoint(bot.id))
    const [saveBotInfo, infoLoading] = usePut(botInfoEndpoint(bot.id))
    const [deleteBot, deleteLoading] = useDelete(botListEndpoint)
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    // RJSF is using ResizeObserver and rendering all the tabs causes a benign error on localhost, this effect just hides the webpack popup caused by the error
    // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            const handler = (event: ErrorEvent) => {
                if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
                    const webpackIFrame = document.getElementById('webpack-dev-server-client-overlay')
                    if (webpackIFrame) {
                        webpackIFrame.remove()
                    }
                }
            }

            window.addEventListener('error', handler)

            return () => window.removeEventListener('error', handler)
        }
    }, [])

    const loading = cloneLoading || infoLoading || deleteLoading
    const readonlyOrLoading = !bot.isEditable || loading

    const handleClone = async (cloneName: string) => {
        if (cloneName) {
            const clone = await cloneBot(cloneName)

            if (clone) {
                toast.success(`Successfully cloned, now editing ${clone.name}`)
                editBotConfig(clone)
            }
        } else {
            toast.warn('Please enter a name for the cloned bot')
        }
    }

    const handleUpdate = async (name: string, description: string) => {
        const success = await saveBotInfo({ name, description })

        if (success) {
            toast.success('Name and description successfully updated')
            editBotConfig({ ...bot, name, description })
            refreshChats()
        } else {
            toast.error(genericErrorMsg)
        }
    }

    const handleDelete = async () => {
        const success = await deleteBot(bot.id)

        if (success) {
            toast.success(`Successfully deleted ${bot.name}`)
            onClose()
        } else {
            toast.error(genericErrorMsg)
        }
    }

    const handleNewChat = () => {
        createChat(bot)
        onClose()
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTabIndex(newValue)
    }

    const showReportTabs = bot.toolType === 'report_generator'

    return (
        <FixedSizeModal show={show} onClose={onClose} heading={bot.name}>
            <ButtonChat onClick={handleNewChat}>
                <AddIcon />
                New session
            </ButtonChat>
            <Tabs value={currentTabIndex} onChange={handleTabChange} variant='scrollable'>
                <Tab label='Basic Details' />
                <Tab label='Configuration' />
                <Tab label='AI Engines' />
                {showReportTabs && <Tab label='Fields' />}
                {showReportTabs && <Tab label='Template' />}
                <Tab label='Share' />
                {showReportTabs && <Tab label='Help' />}
            </Tabs>
            <StyledTabPanel index={0} value={currentTabIndex}>
                <Details
                    id={bot.id}
                    name={bot.name}
                    description={bot.description}
                    loading={loading}
                    readonly={!bot.isEditable}
                    onClone={handleClone}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                />
            </StyledTabPanel>
            <StyledTabPanel index={1} value={currentTabIndex}>
                <BotConfig id={bot.id} readonly={readonlyOrLoading} />
            </StyledTabPanel>
            <StyledTabPanel index={2} value={currentTabIndex}>
                <Balancers id={bot.id} readonly={readonlyOrLoading} />
            </StyledTabPanel>
            {showReportTabs && (
                <>
                    <StyledTabPanel index={3} value={currentTabIndex}>
                        <ReportFields botId={bot.id} readonly={readonlyOrLoading} />
                    </StyledTabPanel>
                    <StyledTabPanel index={4} value={currentTabIndex}>
                        <TemplateDisplay botId={bot.id} readonly={readonlyOrLoading} />
                    </StyledTabPanel>
                </>
            )}
            <StyledTabPanel index={showReportTabs ? 5 : 3} value={currentTabIndex}>
                <ShareBot botId={bot.id} botStatus={bot.status} />
            </StyledTabPanel>
            {showReportTabs && (
                <StyledTabPanel index={6} value={currentTabIndex}>
                    <ReportConfigGuidance />
                </StyledTabPanel>
            )}
        </FixedSizeModal>
    )
}

export default BotConfigModal
