import { useState } from 'react'
import Input from '../common/Input'
import styled, { useTheme } from 'styled-components'
import { mediaQueries, scrollBarStyle } from '../common/styles'
import Dropdown from '../common/Dropdown'
import useDebounce from '../../hooks/useDebounce'
import CrossIcon from '../../icons/CrossIcon'
import MiniIconButton from '../common/MiniIconButton'
import { usePromptContext } from '../../context/PromptContext'
import CenteredContainer from '../common/CenteredContainer'
import {
    noPromptEditPermission,
    noPromptLibrariesAvailable,
    noPromptsAvailable,
    noPromptsAvailableForLibrary,
    noSelectedLibrary,
} from '../../utils/userMessages'
import { TabPanel } from '../common/TabPanel'
import PromptLibrary from './PromptLibrary'
import { sortPrompts } from './promptUtils'
import AddIcon from '../../icons/AddIcon'
import { UUID } from '../../types'
import IconButton from '../common/IconButton'
import TransparentButton from '../common/TransparentButton'
import { Tab, Tabs, Typography } from '@mui/material'
import LibraryTab from './LibraryTab'
import LibraryControls from './LibraryControls'
import PromptSharing from './PromptSharing'
import { canEditPrompt } from './permissions'
import InfoTooltip from '../common/tooltips/InfoTooltip'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const FlexibleHeightSection = styled.div`
    flex: 1;
    overflow-y: auto;
`

const BottomSection = styled.div`
    display: flex;
    justify-content: center;
`

const NoLibrariesContainer = styled(CenteredContainer)`
    flex-direction: column;

    & button {
        margin-top: 32px;
    }
`

const LibraryInfoRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 10px 0;
`

const FilterInfo = styled.div`
    position: relative;
    gap: 123px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${mediaQueries.laptop} {
        gap: 130px;
    }

    @media ${mediaQueries.half} {
        gap: 136px;
    }
`

const FiltersContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`

const InnerFilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const SortLabel = styled(Typography)`
    color: ${props => props.theme.colors.disabled};
    padding-right: 10px;
    padding-top: 14px;
`

const SearchBar = styled(Input)`
    flex-basis: 300px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: 1px solid ${props => props.theme.colors.divider};
    height: 21px;
    margin: 0px 10px 10px 0px;
`

const FilterChipsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    max-height: 90px;
    overflow: auto;
    ${scrollBarStyle}
`

const StyledChip = styled.div`
    border: 1px solid ${props => props.theme.colors.divider};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    height: 32px;
    padding: 0px 10px;
    margin: 5px 0px 0px 10px;
`

export const DeleteFilterButton = styled(MiniIconButton)`
    background-color: ${props => props.theme.colors.divider};
    margin-left: 10px;
    height: 18px;
    width: 18px;
    svg {
        height: 10px;
        width: 10px;
    }
`

const ClearAllFiltersButton = styled(TransparentButton)<{ isVisible: boolean }>`
    opacity: ${props => (props.isVisible ? 1 : 0)};
    transition: background-color 0.2s, opacity 0.3s;
    border: none;
    padding: 2px 8px;
    cursor: ${props => (props.isVisible ? 'pointer' : 'default')};
`

const CreatePromptButton = styled(IconButton)`
    margin-top: 10px;
    margin-right: 10px;
`

const options = ['Alphabetical', 'Most Votes', 'Newest First', 'Oldest First'] as const

export type SortOptions = (typeof options)[number]

const isSortOption = (option: unknown): option is SortOptions => typeof option === 'string' && options.includes(option as SortOptions)

const PromptLibraries = () => {
    const theme = useTheme()
    const { prompts, promptLibraries, selectedLibrary, onLibraryChange, onSelectPrompt } = usePromptContext()

    const [sharingAnchor, setSharingAnchor] = useState<HTMLElement | null>(null)
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [selectedSort, setSelectedSort] = useState<SortOptions>('Alphabetical')
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 800)

    const handleTabSelection = (id: UUID) => {
        setSelectedTags([])
        onLibraryChange(id)
    }

    const removeTag = (tag: string) => {
        const newTags = selectedTags.filter(item => item !== tag)
        setSelectedTags(newTags)
    }

    const clearAllFilters = () => {
        setSelectedTags([])
        setSearchTerm('')
        setSelectedSort('Alphabetical')
    }

    if (!promptLibraries || promptLibraries.length === 0) {
        return (
            <NoLibrariesContainer>
                <Typography variant='h6'>{noPromptLibrariesAvailable}</Typography>
                <IconButton label='Create Library' onClick={() => onLibraryChange('new')}>
                    <AddIcon color={theme.colors.textOnSecondary} />
                </IconButton>
            </NoLibrariesContainer>
        )
    }

    if (!prompts) {
        return (
            <CenteredContainer>
                <Typography variant='h6'>{noPromptsAvailable}</Typography>
            </CenteredContainer>
        )
    }

    if (!selectedLibrary) {
        return (
            <CenteredContainer>
                <Typography variant='h6'>{noSelectedLibrary}</Typography>
            </CenteredContainer>
        )
    }

    const selectedLibraryPrompts = prompts.filter(prompt => selectedLibrary?.promptIds.includes(prompt.id))
    const filteredPrompts = selectedLibraryPrompts.filter(
        prompt =>
            (selectedTags.length === 0 || selectedTags.some(tag => prompt.tagList.map(t => t.name).includes(tag))) &&
            (prompt.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
                prompt.description.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    )

    const sortedFilteredPrompts = sortPrompts(filteredPrompts, selectedSort)
    const sortingFilters = new Set(options)
    const allTags = new Set(selectedLibraryPrompts.flatMap(prompt => prompt.tagList.map(tag => tag.name)))

    const selectedLibraryIndex = promptLibraries.findIndex(library => library === selectedLibrary)
    const currentTabIndex = selectedLibraryIndex === -1 ? 0 : selectedLibraryIndex

    const clearAllVisible = selectedTags.length > 0 || searchTerm !== '' || selectedSort !== 'Alphabetical'

    // react-tabs expects us to use onSelect in controlled mode but we're handling without indexes so pass doNothing

    return (
        <>
            <Tabs value={currentTabIndex}>
                {promptLibraries.map((library, index) => (
                    <Tab
                        key={library.id}
                        onClick={() => handleTabSelection(library.id)}
                        label={<LibraryTab library={library} isSelected={currentTabIndex === index} onShare={setSharingAnchor} />}
                    />
                ))}
                <LibraryControls />
            </Tabs>

            <LibraryInfoRow>
                <Typography>{selectedLibrary.description}</Typography>
                <FilterInfo>
                    <ClearAllFiltersButton
                        onClick={clearAllFilters}
                        isVisible={clearAllVisible}
                        aria-label={clearAllVisible ? 'Clear All Filters' : 'No Filters to Clear'}
                    >
                        <Typography>Clear All</Typography>
                    </ClearAllFiltersButton>
                    <Typography>
                        {filteredPrompts.length} match{filteredPrompts.length !== 1 && 'es'}
                    </Typography>
                </FilterInfo>
            </LibraryInfoRow>

            <FiltersContainer>
                <InnerFilterContainer>
                    <SearchBar placeholder='Search' value={searchTerm} onChange={e => setSearchTerm(e.target.value)}></SearchBar>
                    <FilterWrapper>
                        <Dropdown options={allTags} selected={selectedTags} onSelectionChange={setSelectedTags} multiple placeholder='Filter' />
                        <FilterChipsContainer>
                            {selectedTags.map(tag => (
                                <StyledChip key={tag}>
                                    <Typography variant='h6'>{tag}</Typography>
                                    <DeleteFilterButton name={tag} onClick={() => removeTag(tag)}>
                                        <CrossIcon color={theme.colors.textOnSecondary} strokeWidth='2px' />
                                    </DeleteFilterButton>
                                </StyledChip>
                            ))}
                        </FilterChipsContainer>
                    </FilterWrapper>
                </InnerFilterContainer>
                <InnerFilterContainer>
                    <SortLabel>Sort:</SortLabel>
                    <Dropdown
                        options={sortingFilters}
                        selected={selectedSort}
                        onSelectionChange={selection => {
                            isSortOption(selection) && setSelectedSort(selection)
                        }}
                        placeholder='Alphabetical'
                    />
                </InnerFilterContainer>
            </FiltersContainer>
            {promptLibraries.map((library, index) => (
                <TabPanel key={library.id} index={index} value={currentTabIndex}>
                    <Container>
                        <FlexibleHeightSection>
                            {sortedFilteredPrompts.length === 0 ? (
                                <CenteredContainer>
                                    <Typography variant='h6'>{noPromptsAvailableForLibrary}</Typography>
                                </CenteredContainer>
                            ) : (
                                <PromptLibrary prompts={sortedFilteredPrompts} />
                            )}
                        </FlexibleHeightSection>

                        <BottomSection>
                            <InfoTooltip title={canEditPrompt(library.userRole) ? undefined : noPromptEditPermission}>
                                <CreatePromptButton
                                    label='New prompt'
                                    onClick={() => onSelectPrompt('new')}
                                    disabled={!canEditPrompt(library.userRole)}
                                    aria-label='Create Prompt'
                                >
                                    <AddIcon color={theme.colors.textOnSecondary} />
                                </CreatePromptButton>
                            </InfoTooltip>
                        </BottomSection>
                    </Container>
                </TabPanel>
            ))}

            <PromptSharing
                libraryId={selectedLibrary.id}
                role={selectedLibrary.userRole}
                open={!!sharingAnchor}
                anchorEl={sharingAnchor}
                onClose={() => setSharingAnchor(null)}
            />
        </>
    )
}

export default PromptLibraries
