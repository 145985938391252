export const supportMessage = "A support request has been raised. Please contact us with any further details by using the 'Report Issue' button."

export const genericErrorMsg = `Something went wrong. ${supportMessage}`

export const feedbackFormMsg = 'Your feedback has been submitted. Thank you for helping us to improve the platform.'

export const exceededQuotaMessage =
    'You have exceeded your personal quota this week. Please wait or contact your administrator to increase your quota. You may need to refresh the page after your quota has been increased.'

export const requestSaveMessage = 'You have unsaved changes. Please save them before continuing.'

// Prompts

export const noPromptLibrariesAvailable =
    'There are currently no Prompt Libraries enabled for this bot, please contact an admin to link an existing one or create a new one.'

export const noPromptsAvailable = 'You currently have no Prompts available.'

export const noPromptsAvailableForLibrary = `This Library has no prompts available, or no prompts match your current filters.`

export const noSelectedLibrary = 'Please select a library to view its prompts.'

export const noPromptEditPermission = 'You do not have permission to edit prompts within this library.'

export const noLibraryDeletePermission = 'You do not have permission to delete this library.'

// Config

export const configErrors = 'This tool is live and can not be edited. Use the clone button below to make a private copy.'

export const configTabErrors = 'This tool is live and can not be edited. Use the clone button in the Basic Details tab to make a private copy.'
