import { styled } from '@mui/material'
import DeleteIcon from '../../icons/DeleteIcon'
import { useReportContext } from '../../context/ReportContext'
import { Tab, Tabs } from '@mui/material'
import { TabPanel } from '../common/TabPanel'
import ReportInputList from './ReportInputList'
import TransparentButton from '../common/TransparentButton'

const DeleteButton = styled(TransparentButton)({
    marginLeft: 12,
    padding: 0,
})

const StyledTabPanel = styled(TabPanel)({
    height: 'calc(100% - 50px)', // Account for tabs height
})

interface ReportTabsProps {
    className?: string
}

const ReportTabs = ({ className }: ReportTabsProps) => {
    const { folders, onSelectedFolderChange, selectedFolder, onFolderDelete, reports } = useReportContext()

    const tabs = folders.filter(f => f) // remove the empty string representing single file/folder upload
    const localOnly = !reports?.length // no files have been uploaded to backend yet
    const selectedTabIndex = tabs.findIndex(tab => tab === selectedFolder)

    return (
        <div className={className}>
            {tabs.length > 0 ? (
                <>
                    <Tabs value={selectedTabIndex}>
                        {tabs.map(folder => (
                            <Tab
                                onClick={() => onSelectedFolderChange(folder)}
                                key={folder}
                                label={
                                    <>
                                        {folder}
                                        {localOnly && (
                                            <DeleteButton onClick={() => onFolderDelete(folder)}>
                                                <DeleteIcon />
                                            </DeleteButton>
                                        )}
                                    </>
                                }
                            />
                        ))}
                    </Tabs>
                    {tabs.map((folder, index) => (
                        <StyledTabPanel key={folder} index={index} value={selectedTabIndex}>
                            <ReportInputList />
                        </StyledTabPanel>
                    ))}
                </>
            ) : (
                <ReportInputList />
            )}
        </div>
    )
}

export default ReportTabs
