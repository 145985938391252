import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { FileWithMetadata, UUID, doNothing, isFileWithMetadataArray } from '../types'
import { toast } from 'react-toastify'
import { useChatContext } from './ChatContext'
import { getEmptyGroupedUploadResult, GroupedUploadResult, useChatDocumentUpload } from '../hooks/useChatDocumentUpload'
import { groupFiles } from '../utils/documents'
import { InvalidFormatList, LargeFileList, EmptyFileList } from '../components/notifications/FileListNotifications'
import { tryFilterReportFiles } from '../utils/reports'
import { chatbotFileCountOK } from '../utils/chatBots'

interface FileState {
    files: FileWithMetadata[] | null
    uploading: boolean
    onAttach: (files?: FileWithMetadata[] | FileList | null, folderOverride?: string) => void
    onRemove: (...fileIds: UUID[]) => void
    upload: (clearState?: boolean) => Promise<GroupedUploadResult>
}

export const defaultState: FileState = {
    files: null,
    uploading: false,
    onAttach: doNothing,
    onRemove: doNothing,
    upload: () => Promise.resolve(getEmptyGroupedUploadResult()),
}

export const FileContext = createContext<FileState>(defaultState)

const WithFileContext = ({ children }: PropsWithChildren) => {
    const { currentChat } = useChatContext()

    const [files, setFiles] = useState<FileWithMetadata[] | null>(null)

    const [upload, uploading] = useChatDocumentUpload(currentChat?.id)

    const [prevChatId, setPrevChatId] = useState(currentChat?.id)
    if (currentChat?.id !== prevChatId) {
        setPrevChatId(currentChat?.id)
        setFiles(null)
    }

    const validateAndAttach = (newFiles: FileWithMetadata[] | FileList) => {
        const groupedFiles = groupFiles(newFiles)
        groupedFiles.tooLarge.length > 0 && toast.warn(<LargeFileList files={groupedFiles.tooLarge} />)
        groupedFiles.invalidFormat.length > 0 && toast.warn(<InvalidFormatList files={groupedFiles.invalidFormat} />)
        groupedFiles.empty.length > 0 && toast.warn(<EmptyFileList files={groupedFiles.empty} />)

        if (groupedFiles.valid.length > 0) {
            setFiles(f => (f ? f.concat(groupedFiles.valid) : groupedFiles.valid))
        }
    }

    const handleAttach = (newFiles?: FileWithMetadata[] | FileList | null, folderOverride?: string) => {
        if (newFiles) {
            switch (currentChat?.bot.toolType) {
                case 'chatbot':
                    if (chatbotFileCountOK(currentChat, newFiles)) {
                        validateAndAttach(newFiles)
                    }
                    break
                case 'report_generator':
                    if (isFileWithMetadataArray(newFiles)) {
                        const filteredFiles = tryFilterReportFiles(newFiles)

                        if (filteredFiles) {
                            validateAndAttach(folderOverride ? filteredFiles.map(f => ({ ...f, folderName: folderOverride })) : filteredFiles)
                        } else {
                            toast.warn('There were no valid files to attach. Please ensure files are located directly in subfolders.')
                        }
                    } else {
                        validateAndAttach(newFiles)
                    }
                    break
                default:
                    validateAndAttach(newFiles)
                    break
            }
        }
    }

    const handleRemove = (...fileIds: UUID[]) => {
        const updatedFiles = files?.filter(f => !fileIds.includes(f.id))
        setFiles(updatedFiles?.length ? updatedFiles : null)
    }

    /**
     * Uploads the attached files to the server
     *
     * @param clearState - Whether or not all attached files should be cleared after the upload. By default only successfully uploaded ones are.
     * @returns Ids of files grouped by success or failure to upload
     */
    const handleUpload = async (clearState = false) => {
        if (!currentChat || !files) {
            toast.warn('Please attach a file to upload')
            return getEmptyGroupedUploadResult()
        } else {
            const groupedResult = await upload(files)

            if (clearState) {
                setFiles(null)
            } else if (groupedResult.uploadedIds.length) {
                handleRemove(...groupedResult.uploadedIds)
            }

            return groupedResult
        }
    }

    return (
        <FileContext.Provider value={{ files, uploading, onAttach: handleAttach, upload: handleUpload, onRemove: handleRemove }}>
            {children}
        </FileContext.Provider>
    )
}

const useFileContext = () => useContext(FileContext)

export { WithFileContext, useFileContext }
