import { DefaultTheme, css } from 'styled-components'
import { BaseColors } from '../../types'
import { CSSObject, emphasize, Theme } from '@mui/material'

const lightColors: BaseColors = {
    primary: '#FFFFFF',
    textOnSecondary: '#F6F6F6',
    descriptionText: '#75727E',
    tertiary: '#DBDDDF',
    focus: '#FFFFFF',
    highlighted: '#FAFAFA',
    disabled: '#B7BCBF',
    disabledBackground: '#F0F0F0',
    background: '#FFFFFF',
    paper: '#F4F5F6',
    divider: '#DBDDDF',
    errorBackground: '#FFCCCC',
    error: '#CB3E3E',
}

const darkColors: BaseColors = {
    primary: '#1C1C1F',
    textOnSecondary: '#FFFFFF',
    descriptionText: '#969696',
    tertiary: '#2F2F34',
    focus: '#1C1C1F',
    highlighted: '#18181B',
    disabled: '#676772',
    disabledBackground: '#343438',
    background: '#1C1C1F',
    paper: '#212125',
    divider: '#2F2F34',
    errorBackground: '#FFCCCC',
    error: '#CB3E3E',
}

type BaseTheme = Omit<DefaultTheme, 'colors' | 'isDark'>

const springbokTheme: BaseTheme = {
    company: 'Springbok',
    logo: {
        width: 260,
        height: 150,
    },
}

export const springbokLightTheme: DefaultTheme = {
    ...springbokTheme,
    colors: {
        ...lightColors,
        text: '#1D182E',
        secondary: '#130934',
    },
    isDark: false,
}

export const springbokDarkTheme: DefaultTheme = {
    ...springbokTheme,
    colors: {
        ...darkColors,
        text: '#FFFFFF',
        secondary: '#5242B6',
    },
    isDark: true,
}

const charlesRussellSpeechlysTheme: BaseTheme = {
    company: 'CharlesRussellSpeechlys',
    logo: {
        width: 200,
        height: 200,
    },
}

export const charlesRussellSpeechlysLightTheme: DefaultTheme = {
    ...charlesRussellSpeechlysTheme,
    colors: {
        text: '#000000',
        secondary: '#139b5b',
        ...lightColors,
    },
    isDark: false,
}

export const charlesRussellSpeechlysDarkTheme: DefaultTheme = {
    ...charlesRussellSpeechlysTheme,
    colors: {
        text: '#FFFFFF',
        secondary: '#139b5b',
        ...darkColors,
    },
    isDark: true,
}

const charlesRussellSpeechlysUATTheme: BaseTheme = {
    company: 'CharlesRussellSpeechlysUAT',
    logo: {
        width: 200,
        height: 200,
    },
}

export const charlesRussellSpeechlysUATLightTheme: DefaultTheme = {
    ...charlesRussellSpeechlysUATTheme,
    colors: {
        text: '#000000',
        secondary: '#e95260',
        ...lightColors,
    },
    isDark: false,
}

export const charlesRussellSpeechlysUATDarkTheme: DefaultTheme = {
    ...charlesRussellSpeechlysUATTheme,
    colors: {
        text: '#FFFFFF',
        secondary: '#e95260',
        ...darkColors,
    },
    isDark: true,
}

export const scrollBarStyle = css`
    /*SCROLLBAR*/
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    ::-webkit-scrollbar-track {
        background: ${({ theme: { isDark } }) => (isDark ? '#2D2D2D' : '#e9e9e9')};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme: { isDark } }) => (isDark ? '#b9b9b9' : '#BFBFBF')};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme: { isDark } }) => (isDark ? '#424242' : '#DCDCDC')};
    }
    /* Styles for Firefox */
    :root {
        scrollbar-width: thin;
        scrollbar-color: ${({ theme: { isDark } }) => (isDark ? '#2D2D2D #424242' : '#e9e9e9 #DCDCDC')};
    }
`

export const thickScrollbarStyle: CSSObject = {
    '::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
    },
}

export const overflowText: CSSObject = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

//Temporary solution. MUI's Palette has an Action object which should hold that information when we fully migrate.
export const highlightOnHover = (color: string) => css`
    :hover {
        background-color: ${emphasize(color, 0.15)};
    }
`

export const sidebarTransitions = css`
    transition: all 0.3s ease, opacity 0.1s ease;
`

export const sidebarWidth = 400

export const mainElementMaxWidth = css`
    max-width: 1200px;
`

export const zIndexes = {
    elevated: 1,
    button: 2,
    dropdown: 3,
    navigation: 10,
    mobileNavigationButton: 11,
    modal: 100,
    tooltips: 110,
}

export const breakpoints = {
    mobile: 850,
    half: 960,
    laptop: 1300,
    laptopVertical: 900,
}

export const mediaQueries = {
    mobile: `(max-width: ${breakpoints.mobile}px)`,
    half: `(max-width: ${breakpoints.half}px)`,
    laptop: `(max-width: ${breakpoints.laptop}px)`,
    laptopVertical: `(max-height: ${breakpoints.laptopVertical}px)`,
}

const headerHeight = '90px'
const initialFooterHeight = '80px'
export const mainAreaLayoutStyles = {
    headerHeight,
    header: css`
        min-height: ${headerHeight};

        @media ${mediaQueries.mobile} {
            margin: 0px;
            padding: 0 24px;
            height: auto;
        }
    `,
    content: css`
        flex: 1;
        max-height: calc(100dvh - ${headerHeight} - ${initialFooterHeight});
    `,
    footer: css`
        flex: 0 2 ${initialFooterHeight};
    `,
}

export const areaLayoutStyles = (theme: Theme) => ({
    header: {
        minHeight: headerHeight,
        [theme.breakpoints.down('md')]: {
            margin: 0,
            padding: '0 24px',
            height: 'auto',
        },
    },

    content: {
        flex: 1,
        maxHeight: `calc(100dvh - ${headerHeight} - ${initialFooterHeight})`,
    },

    footer: {
        flex: `0 2 ${initialFooterHeight}`,
    },
})
