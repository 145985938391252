import { useTheme } from '@mui/material'
import { SVGProps } from 'react'

const ShareIcon = (props: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={15} height={15} fill='none' {...props}>
            <path
                fill={palette.text.primary}
                d='M12.5 10.602c-.633 0-1.2.226-1.633.58L4.925 8.057A2.24 2.24 0 0 0 5 7.53c0-.18-.033-.354-.075-.527L10.8 3.908c.45.377 1.042.61 1.7.61 1.383 0 2.5-1.009 2.5-2.259S13.883 0 12.5 0 10 1.009 10 2.259c0 .18.033.354.075.527L4.2 5.881a2.638 2.638 0 0 0-1.7-.61c-1.383 0-2.5 1.01-2.5 2.26 0 1.25 1.117 2.258 2.5 2.258.658 0 1.25-.233 1.7-.61l5.933 3.133a1.934 1.934 0 0 0-.066.49c0 1.212 1.091 2.198 2.433 2.198 1.342 0 2.433-.986 2.433-2.199 0-1.212-1.091-2.199-2.433-2.199Z'
            />
        </svg>
    )
}

export default ShareIcon
