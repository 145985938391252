import styled from 'styled-components'
import { mainAreaLayoutStyles } from './common/styles'
import { environmentVariables } from '../env'
import WarningArea from './common/WarningArea'
import { PropsWithChildren } from 'react'
import { exceededQuotaMessage } from '../utils/userMessages'
import { useUserContext } from '../context/UserContext'
import { useChatContext } from '../context/ChatContext'

const Footer = styled.div`
    ${mainAreaLayoutStyles.footer}
`

const WithValidation = ({ children }: PropsWithChildren) => {
    const { isActive, hasRemainingQuota } = useUserContext()
    const { currentChat } = useChatContext()

    if (currentChat?.status === 'archived' || currentChat?.bot.status === 'archived') {
        return (
            <Footer>
                <WarningArea message={`This session has been archived and will be deleted within ${environmentVariables.chatRetentionDays} days.`} />
            </Footer>
        )
    }

    if (!isActive) {
        return (
            <Footer>
                <WarningArea message={`You do not have an active subscription. Please contact your administrator to renew your subscription.`} />
            </Footer>
        )
    }

    if (!hasRemainingQuota) {
        return (
            <Footer>
                <WarningArea message={exceededQuotaMessage} />
            </Footer>
        )
    }

    return children
}

export default WithValidation
