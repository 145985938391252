import styled from 'styled-components'
import Button from '../common/Button'
import { useFileContext } from '../../context/FileContext'
import CenteredContainer from '../common/CenteredContainer'
import { useReportContext } from '../../context/ReportContext'
import { useGet } from '../../hooks/useGet'
import { downloadReportsEndpoint } from '../../endpoints'
import InfoTooltip from '../common/tooltips/InfoTooltip'
import { REPORT_CONFIG_FORM_ID } from './ReportConfig'

const Container = styled(CenteredContainer)`
    justify-content: end;
    padding: 0 36px;
    width: unset;
`

interface ReportControlsProps {
    className?: string
}

const ReportControls = ({ className }: ReportControlsProps) => {
    const { files } = useFileContext()
    const { currentChat, folders, loading, generateReport, reports } = useReportContext()

    const [, downloadLoading, downloadAll] = useGet<Blob>(downloadReportsEndpoint(currentChat.id), {
        requestOnRender: false,
        responseType: 'blob',
    })

    if (loading || (!files && !reports)) {
        return null
    }

    return (
        <Container className={className}>
            {reports ? (
                <InfoTooltip title='Download a zip folder containing all output documents'>
                    <Button onClick={downloadAll} disabled={downloadLoading || reports.every(r => r.state !== 'completed')} aria-label='download all'>
                        Download All
                    </Button>
                </InfoTooltip>
            ) : (
                <Button
                    {...(currentChat.bot.supportsSessionConfig ? { form: REPORT_CONFIG_FORM_ID } : { onClick: () => generateReport() })}
                    disabled={!files}
                    aria-label='generate report'
                >
                    {`Generate${folders.length > 1 ? ' All' : ''}`}
                </Button>
            )}
        </Container>
    )
}

export default ReportControls
