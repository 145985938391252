import { AiEngine, Bot, BotMessage, Chat, Document, DocumentUserMessage, ReportChat, TextUserMessage, User } from '../types'

export const mockUser: User = {
    id: '09876543-21dc-ba98-7654-3210fedcba09',
    tenantId: '9df4d0cb-9cc2-47d6-8974-5ebfef6cd949',
    name: 'Luke Skywalker',
    email: 'luke.skywalker@jediorder.com',
    role: 0,
    quota: 50,
    costThisWeek: 0,
    isActive: true,
}

const mockAiEngine: AiEngine = {
    displayName: 'gpt 3.5',
    region: 'UK',
    model: 'gpt-3.5-turbo',
}

export const mockReportBot: Bot = {
    id: 'ce8ae204-fccf-4a0f-877c-673037467703',
    name: 'New report',
    description: 'Generate a report',
    status: 'live',
    toolType: 'report_generator',
    isEditable: false,
    supportsDocumentUpload: true,
    supportsMultidocumentReport: true,
    supportsFields: true,
    supportsSessionConfig: false,
    responseMode: 'spool',
    classification: 'GenericReportGenerator',
    aiEngines: [mockAiEngine],
}

export const mockBots: Bot[] = [
    {
        id: '825b2971-c7e7-47c8-aab8-d6caff5adbfa',
        name: 'Echo Bot',
        description: 'Echo... echo... echo...',
        status: 'live',
        toolType: 'chatbot',
        isEditable: false,
        responseMode: 'spool',
        classification: 'GeneralAssistant',
        aiEngines: [mockAiEngine],
    },
    {
        id: 'ba62bd7a-b12c-4ad0-baff-d8ce447595b5',
        name: 'ChatGPT',
        description: 'Chat to ChatGPT!',
        status: 'live',
        toolType: 'chatbot',
        isEditable: false,
        responseMode: 'spool',
        classification: 'GeneralAssistant',
        aiEngines: [mockAiEngine],
    },
    mockReportBot,
]

export const mockArchivedBot: Bot = {
    id: 'ba62bd7a-b12c-4ad0-baff-d8ce447595b4',
    name: 'ArchivedBot',
    description: 'Browse your archived chat!',
    status: 'archived',
    toolType: 'chatbot',
    isEditable: false,
    responseMode: 'spool',
    classification: 'GeneralAssistant',
    aiEngines: [mockAiEngine],
}

export const mockDeprecatedBot: Bot = {
    id: 'ba62bd7a-b12c-4ad0-baff-d8ce447595b3',
    name: 'DeprecatedBot',
    description: 'deprecation',
    status: 'deprecated',
    toolType: 'chatbot',
    isEditable: false,
    responseMode: 'spool',
    classification: 'GeneralAssistant',
    aiEngines: [mockAiEngine],
}

export const mockBotWithDocumentSupport: Bot = {
    id: 'd7ce74f8-7916-478d-9b84-ba9ed9fffc32',
    name: 'Docu Bot',
    description: 'Loves PDF, hates PNG',
    supportsDocumentUpload: true,
    status: 'live',
    toolType: 'chatbot',
    isEditable: false,
    responseMode: 'spool',
    classification: 'GeneralAssistant',
    aiEngines: [mockAiEngine],
}

export const mockStreamingBot: Bot = {
    id: '6b9df8d9-131b-4dde-bc2c-177320891a64',
    name: 'Stream Bot',
    description: 'Loves websockets',
    status: 'live',
    toolType: 'chatbot',
    isEditable: false,
    responseMode: 'stream',
    classification: 'GeneralAssistant',
    aiEngines: [mockAiEngine],
}

export const mockChats: Chat[] = [
    {
        id: '64d4ae5c-3a06-4ec3-9744-6c3ef6eab0ae',
        bot: mockBots[0],
        title: 'Chat 1',
        latestTimestamp: '2023-04-13T14:56:37.370530+00:00',
        status: 'active',
        progressUpdate: null,
    },
    {
        id: '9852e37b-323f-48c0-b56f-6748e6ad1ac7',
        bot: mockBots[0],
        title: 'Chat 2',
        latestTimestamp: '2023-04-13T15:08:39.937545+00:00',
        status: 'active',
        progressUpdate: null,
    },
    {
        id: '3a0860af-a399-4a7e-baa9-89152574a19b',
        bot: mockBots[1],
        title: 'Chat 3',
        latestTimestamp: '2023-04-13T15:08:41.609857+00:00',
        status: 'active',
        progressUpdate: null,
    },
]

export const mockChatArchivedBot: Chat = {
    id: '3a0860af-a399-4a7e-baa9-89152574a19b',
    bot: mockArchivedBot,
    title: 'Chat with archived bot',
    latestTimestamp: '2023-04-13T15:08:41.609857+00:00',
    status: 'active',
    progressUpdate: null,
}

export const mockChatDeprecatedBot: Chat = {
    id: '3a0860af-a399-4a7e-baa9-89152574a19b',
    bot: mockDeprecatedBot,
    title: 'Chat with archived bot',
    latestTimestamp: '2023-04-13T15:08:41.609857+00:00',
    status: 'active',
    progressUpdate: null,
}

export const mockLockedChat: Chat = {
    id: mockChats[2].id,
    bot: mockBots[0],
    title: 'Locked',
    latestTimestamp: '2023-05-24T11:11:11.111111+00:00',
    status: 'locked',
    progressUpdate: null,
}

export const mockArchivedChat: Chat = {
    id: '555da486-3528-4f0f-a1d1-8878add03ea1',
    bot: mockBots[0],
    title: 'Locked',
    latestTimestamp: '2023-05-24T11:11:11.111111+00:00',
    status: 'archived',
    progressUpdate: null,
}

export const mockChatWithoutTitle: Chat = {
    id: '049660b7-a84c-4f68-ab33-554d88c7242a',
    bot: mockBots[0],
    title: null,
    latestTimestamp: '2023-05-24T11:11:11.111111+00:00',
    status: 'active',
    documentCount: 0,
    progressUpdate: null,
}

const mockChatWithDocumentBot: Chat = {
    id: '9a0d67e4-b8dc-4160-bd24-717777a9e93d',
    bot: mockBotWithDocumentSupport,
    title: 'Doc Chat',
    latestTimestamp: '2023-09-20T16:03:00.000000+00:00',
    status: 'active',
    progressUpdate: null,
}

export const mockReportChat: ReportChat = {
    id: '40161979-0aa6-4406-a0a4-520ed8a5d007',
    title: 'Placeholder',
    bot: {
        id: '565771ab-96f2-47b0-9883-537d760b47fe',
        name: 'Mock Report Bot',
        description: 'Placeholder report bot',
        supportsDocumentUpload: true,
        status: 'not_configured',
        toolType: 'report_generator',
        isEditable: false,
        responseMode: 'stream',
        classification: 'GenericReportGenerator',
        aiEngines: [],
        supportsMultidocumentReport: false,
        supportsFields: false,
        supportsSessionConfig: false,
    },
    latestTimestamp: '2024-11-20 13:48:00',
    status: 'archived',
    progressUpdate: null,
}

export const mockTextUserMessage: TextUserMessage = {
    id: '123e4567-e89b-12d3-a456-426655440000',
    chatId: mockChats[2].id,
    timestamp: '2023-04-17 11:30:45',
    fromUser: true,
    text: 'A man is washing his car with his son. The son asks, Dad cant you use the sponge?!',
    messageType: 'normal',
}

export const mockBotMessage: BotMessage = {
    id: '234e5678-f901-23d4-b567-537766550000',
    chatId: mockChats[2].id,
    timestamp: '2023-04-17 11:31:00',
    fromUser: false,
    text: "Ha! That's a good one!",
    messageType: 'normal',
}

export const mockMessages: Array<BotMessage | TextUserMessage> = [
    mockTextUserMessage,
    mockBotMessage,
    {
        id: '345e6789-abcd-34e5-c678-648877660000',
        chatId: mockChats[2].id,
        timestamp: '2023-04-17 11:31:15',
        fromUser: true,
        text: 'Why do divers never roll forwards off the boat?',
        messageType: 'normal',
    },
    {
        id: '456e7890-bcde-45f6-d789-759988770000',
        chatId: mockChats[2].id,
        timestamp: '2023-04-17 11:31:30',
        fromUser: true,
        text: "Because they'd still be on the boat!",
        messageType: 'normal',
    },
    {
        id: '567e8901-cdef-56g7-e890-85aabb990000',
        chatId: mockChats[2].id,
        timestamp: '2023-04-17 11:31:45',
        fromUser: false,
        text: 'I was wondering why divers never go off the front of the boat... and then it hit me...',
        messageType: 'normal',
    },
]

export const mockDocument: Document = {
    id: 'e870dd98-7b08-46de-98a3-2b56a369685d',
    name: 'TypeScript for Dummies.pdf',
    state: 'extracted',
}

const mockDocuments: Document[] = [
    mockDocument,
    {
        id: '6b34c71d-4655-4527-b100-9e8db947f729',
        name: 'GPT for Dummies.pdf',
        state: 'extracted',
    },
]

export const mockMultiDocumentMessage: DocumentUserMessage = {
    id: 'd6cf0ebb-62bf-496d-b194-9298b4cfaaeb',
    chatId: mockChatWithDocumentBot.id,
    timestamp: '2023-09-20T16:07:00.000000+00:00',
    fromUser: true,
    text: 'This message displays multi docs!',
    messageType: 'normal',
    documents: mockDocuments,
}

export const mockDocumentMessage: DocumentUserMessage = {
    id: 'd6cf0ebb-62bf-496d-b194-9298b4cfaaeb',
    chatId: mockChatWithDocumentBot.id,
    timestamp: '2023-09-20T16:07:00.000000+00:00',
    fromUser: true,
    text: 'This message displays multi docs!',
    messageType: 'normal',
    documents: [mockDocument],
}

export const mockEmptyDocumentMessage: DocumentUserMessage = {
    id: 'd6cf0ebb-62bf-496d-b194-9298b4cfaaeb',
    chatId: mockChatWithDocumentBot.id,
    timestamp: '2023-09-20T16:07:00.000000+00:00',
    fromUser: true,
    messageType: 'normal',
    documents: [],
}
