import { styled, Typography } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const Text = styled(Typography)({
    textDecoration: 'underline',
    cursor: 'help',
})

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: '700px',
    },
}))

const UploadInstructions = () => (
    <>
        <StyledTooltip
            title={
                <div>
                    <Typography>
                        To upload multiple sets of documents for bulk processing, please structure the data into folders. All files for a report should be in
                        their own folder, as pictured:{' '}
                    </Typography>
                    <pre>
                        {`───Uploaded Folder
        │   FileA.pdf           <--- This file will be ignored
        │
        ├───Folder 1            <--- Report 1 will be generated from this folder
        │   │   File1-1.pdf
        │   │   File1-2.pdf
        │   │
        │   └───Subfolder       <--- Deeper subfolders will be ignored
        │           FileB.pdf   <--- This file will be ignored
        │
        └───Folder 2            <--- Report 2 will be generated from this folder
                File2-1.pdf
            `}
                    </pre>
                    <Typography>Files which fall outside of this structure (FileA.pdf, FileB.pdf) will be ignored.</Typography>
                </div>
            }
        >
            <Text>Bulk uploading multiple reports from a single folder?</Text>
        </StyledTooltip>
    </>
)

export default UploadInstructions
