import { useFileContext } from '../../context/FileContext'
import UploadArea from '../common/UploadArea'
import { useReportContext } from '../../context/ReportContext'
import CenteredContainer from '../common/CenteredContainer'
import ReportLoading from '../report/ReportLoading'
import UploadInstructions from '../report/UploadInstructions'
import ReportTabs from './ReportTabs'
import ReportDisplay from './ReportDisplay'
import { styled } from '@mui/material'
import ReportConfig from './ReportConfig'

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: 50,

    '& > *': {
        flex: 1,
    },

    '&::before': {
        content: '""',
    },
    '&::after': {
        content: '""',
    },

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        overflow: 'auto',
    },
}))

interface ReportContentProps {
    className?: string
}

const ReportContent = ({ className }: ReportContentProps) => {
    const { files } = useFileContext()
    const { currentChat, loading, reports, selectedFolder } = useReportContext()

    if (loading) {
        return (
            <CenteredContainer className={className}>
                <ReportLoading />
            </CenteredContainer>
        )
    }

    if (!files && !reports) {
        return (
            <UploadArea className={className} clickable folderOverride={selectedFolder} alwaysVisible>
                {currentChat.bot.supportsMultidocumentReport && <UploadInstructions />}
            </UploadArea>
        )
    }

    if (reports) {
        return <ReportDisplay className={className} reports={reports} />
    }

    return (
        <Container className={className}>
            {currentChat.bot.supportsSessionConfig && <ReportConfig />}
            <ReportTabs />
        </Container>
    )
}

export default ReportContent
