import FileIcon from '../../icons/FileIcon'
import { overflowText } from './styles'
import CrossIcon from '../../icons/CrossIcon'
import { useFileContext } from '../../context/FileContext'
import { UUID } from '../../types'
import { PropsWithChildren } from 'react'
import ErrorCard from './ErrorCard'
import { styled, Typography } from '@mui/material'
import TransparentButton from './TransparentButton'

const Container = styled('div')(({ theme }) => ({
    padding: '16px',
    display: 'grid',
    gridTemplateColumns: '48px 1fr auto',
    gridTemplateRows: '52% 48%',
    gridRowGap: '5px',
    backgroundColor: theme.palette.background.default,
    border: '1px solid #d8d8d8',
    borderRadius: '8px',
}))

const StyledFileIcon = styled(FileIcon)({
    gridArea: '1 / 1 / 3 / 2',
    justifySelf: 'center',
    alignSelf: 'center',
    width: 36,
    height: 36,
})

const Name = styled(Typography)({
    gridArea: '1 / 2 / 2 / 3',
    margin: 0,
    alignSelf: 'end',
    ...overflowText,
})

const Size = styled(Typography)(({ theme }) => ({
    gridArea: '2 / 2 / 3 / 3',
    margin: 0,
    color: theme.palette.text.disabled,
    ...overflowText,
}))

const ChildContainer = styled('div')({
    gridArea: '1 / 3 / 3 / 4',
    justifySelf: 'end',
    alignSelf: 'center',
    margin: '0 0 0 30px',
})

const RemoveButton = styled(TransparentButton)({
    padding: 5,
})

interface BaseCardProps extends PropsWithChildren {
    className?: string
    name: string
    size?: number
    error?: string
}

const BaseCard = ({ className, children, name, size, error }: BaseCardProps) => (
    <div className={className}>
        <Container>
            <StyledFileIcon />
            <Name>{name}</Name>
            {size && <Size variant='body2'>{`${size / 1000}KB`}</Size>}
            <ChildContainer>{children}</ChildContainer>
        </Container>
        {error && <ErrorCard error={error} />}
    </div>
)

interface FileCardProps {
    className?: string
    id: UUID
    name: string
    size: number
    error?: string
}

const FileCard = ({ id, ...rest }: FileCardProps) => {
    const { onRemove } = useFileContext()

    return (
        <BaseCard {...rest}>
            <RemoveButton onClick={() => onRemove(id)} aria-label='remove file'>
                <CrossIcon width={25} height={25} />
            </RemoveButton>
        </BaseCard>
    )
}

export default FileCard
