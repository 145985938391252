import { styled } from '@mui/material'
import { useReportContext } from '../../context/ReportContext'
import CardList from '../common/CardList'
import FileCard from '../common/FileCard'
import { useRef } from 'react'
import HiddenAttachInput from '../common/HiddenAttachInput'
import UploadArea from '../common/UploadArea'
import { cardListStyles } from './styles'
import TransparentButton from '../common/TransparentButton'
import AddIcon from '../../icons/AddIcon'

const ListContainer = styled('div')(({ theme }) => ({
    ...cardListStyles,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}))

const AddButton = styled(TransparentButton)({
    margin: '18px 0 10px auto',
})

const StyledUploadArea = styled(UploadArea)({
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '60%',
    height: `60%`,
})

const ReportInputList = () => {
    const { failedUploadFileIds, folderFiles, selectedFolder } = useReportContext()

    const uploadRef = useRef<HTMLInputElement>(null)

    return (
        <ListContainer>
            <AddButton onClick={() => uploadRef.current?.click()} aria-label='add files'>
                <AddIcon />
                Add files
                <HiddenAttachInput ref={uploadRef} folderOverride={selectedFolder} />
            </AddButton>
            <CardList>
                {folderFiles?.map(({ id, file }) => (
                    <FileCard
                        key={id}
                        id={id}
                        name={file.name}
                        size={file.size}
                        error={failedUploadFileIds?.includes(id) ? 'Document failed to upload, please try again' : undefined}
                    />
                ))}
            </CardList>
            <StyledUploadArea folderOverride={selectedFolder} />
        </ListContainer>
    )
}

export default ReportInputList
