import { styled, Typography } from '@mui/material'
import { formatToUSD } from '../../../utils/formatting'
import Editable from '../../common/Editable'
import InfoIcon from '../../../icons/InfoIcon'
import InfoTooltip from '../../common/tooltips/InfoTooltip'
import { Dollar } from '../../../types'

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})

const InnerContainer = styled('span')({
    display: 'inline-flex',
})

const StyledEditable = styled(Editable)({
    maxWidth: '80px',

    '& .MuiInputBase-root, .MuiInputBase-input': {
        textAlign: 'center',
    },
})

interface CostLimitProps {
    className?: string
    costCap: Dollar
    onCostCapChange: (newCap: Dollar) => void
}

const CostLimit = ({ className, costCap, onCostCapChange }: CostLimitProps) => (
    <Container className={className}>
        <InnerContainer>
            <Typography variant='h6' sx={{ gridArea: '1 / 1 / 2 / 2' }}>
                Run Limit
            </Typography>
            <InfoTooltip
                title='Set a limit for each Table run. If the limit is reached, the run will stop and show results up to that point. You can change this limit between runs.'
                sx={{ margin: '3px 0 0 5px' }}
            >
                <InfoIcon />
            </InfoTooltip>
        </InnerContainer>
        <StyledEditable label={costCap.toString()} onLabelSubmit={update => onCostCapChange(Number(update))} type='numeric' textFormatter={formatToUSD} />
    </Container>
)

export default CostLimit
