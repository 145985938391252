import styled from 'styled-components'
import { mainAreaLayoutStyles } from '../common/styles'
import ReportControls from './ReportControls'
import useIsMobile from '../../hooks/useIsMobile'
import { WithReportContext } from '../../context/ReportContext'
import UnavailableInMobile from '../common/UnavailableInMobile'
import ChatHeader from '../ChatHeader'
import WithValidation from '../WithValidation'
import ReportContent from './ReportContent'
import { ReportChat } from '../../types'

const StyledHeader = styled(ChatHeader)`
    ${mainAreaLayoutStyles.header}
`

const Content = styled(ReportContent)`
    ${mainAreaLayoutStyles.content}
`

const Footer = styled(ReportControls)`
    ${mainAreaLayoutStyles.footer}
`

interface ReportLayoutProps {
    currentChat: ReportChat
}

const ReportLayout = ({ currentChat }: ReportLayoutProps) => {
    const isMobile = useIsMobile()

    if (isMobile) {
        return <UnavailableInMobile featureName='Report Generators' />
    }

    return (
        <WithReportContext currentChat={currentChat}>
            <StyledHeader />
            <Content />
            <WithValidation>
                <Footer />
            </WithValidation>
        </WithReportContext>
    )
}

export default ReportLayout
