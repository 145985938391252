import ErrorIcon from '../../icons/ErrorIcon'
import { PropsWithChildren } from 'react'
import { styled, Typography } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    display: 'grid',
    gridAutoColumns: '24px 1fr',
    gridColumnGap: '10px',
    alignItems: 'center',
    borderRadius: '6px',
    margin: '8px 0',
    padding: '6px 9px',
    backgroundColor: theme.palette.error.light,
}))

const Icon = styled(ErrorIcon)({
    marginRight: '12px',
})

const Text = styled(Typography)(({ theme }) => ({
    gridColumn: '2',
    color: theme.palette.error.dark,
}))

interface ErrorCardProps extends PropsWithChildren {
    className?: string
    error?: string
}

const ErrorCard = ({ children, className, error }: ErrorCardProps) => (
    <Container className={className}>
        <Icon />
        <Text>{children ?? error}</Text>
    </Container>
)

export default ErrorCard
