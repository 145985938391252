import { useMsal } from '@azure/msal-react'
import styled, { useTheme } from 'styled-components'
import { useDarkModeContext } from '../context/DarkModeContext'
import { useUserContext } from '../context/UserContext'
import CogIcon from '../icons/CogIcon'
import DarkModeIcon from '../icons/DarkModeIcon'
import LogoutIcon from '../icons/LogoutIcon'
import CenteredContainer from './common/CenteredContainer'
import UnstyledButton from './common/UnstyledButton'
import { highlightOnHover, mediaQueries } from './common/styles'

const menuWidth = 200

const MenuContainer = styled(CenteredContainer)`
    flex-direction: column;
    position: absolute;
    bottom: 50px;
    left: 46px;
    width: ${menuWidth}px;
    height: auto;
    border-radius: 22px;
    padding: 12px;
    background-color: ${props => props.theme.colors.secondary};

    @media ${mediaQueries.mobile} {
        margin: 0px 32px;
        left: 24px;
        border-radius: 10px;
    }
`

const MenuButton = styled(UnstyledButton)`
    width: ${menuWidth}px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    border-radius: 12px;
    background-color: ${props => props.theme.colors.secondary};

    ${props => highlightOnHover(props.theme.colors.secondary)}

    @media ${mediaQueries.mobile} {
        width: 100%;
        border-radius: 10px;
    }
`

const MenuButtonText = styled.span`
    margin-left: 10px;
    color: ${props => props.theme.colors.textOnSecondary};
`

interface SettingsMenuProps {
    onAccountSettingsOpen: () => void
}

const SettingsMenu = ({ onAccountSettingsOpen }: SettingsMenuProps) => {
    const {
        colors: { textOnSecondary },
    } = useTheme()
    const { instance } = useMsal()
    const { isDarkMode, toggleDarkMode } = useDarkModeContext()
    const { isAccountManager } = useUserContext()

    const darkModeLabel = `${isDarkMode ? 'Light' : 'Dark'} Mode`

    return (
        <MenuContainer>
            {isAccountManager && (
                <MenuButton type='button' onClick={onAccountSettingsOpen} aria-label='Account Settings'>
                    <CogIcon color={textOnSecondary} />
                    <MenuButtonText>Account Settings</MenuButtonText>
                </MenuButton>
            )}
            <MenuButton type='button' onClick={toggleDarkMode} aria-label={darkModeLabel}>
                <DarkModeIcon isDarkMode={isDarkMode} color={textOnSecondary} />
                <MenuButtonText>{darkModeLabel}</MenuButtonText>
            </MenuButton>
            <MenuButton type='button' onClick={() => instance.logoutPopup()} aria-label='Logout'>
                <LogoutIcon color={textOnSecondary} />
                <MenuButtonText>Logout</MenuButtonText>
            </MenuButton>
        </MenuContainer>
    )
}

export default SettingsMenu
