import { styled, Typography } from '@mui/material'
import TranslationConfig from './TranslationConfig'
import { useReportContext } from '../../context/ReportContext'

export const REPORT_CONFIG_FORM_ID = 'report-config'

const Container = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

interface ReportConfigProps {
    className?: string
}

const ReportConfig = ({ className }: ReportConfigProps) => {
    const { currentChat } = useReportContext()

    return (
        <Container className={className}>
            <Typography variant='h1'>Configuration</Typography>
            {currentChat.bot.classification === 'TranslationReportGenerator' ? (
                <TranslationConfig />
            ) : (
                <Typography>Configuration can not be provided for this tool.</Typography>
            )}
        </Container>
    )
}

export default ReportConfig
