import { useState } from 'react'
import { toast } from 'react-toastify'
import { CreateBotPayload, CreateBotResponse, NewBotOption } from '../../apiTypes'
import { useBotContext } from '../../context/BotContext'
import { botListEndpoint, newBotOptionsEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import { usePost } from '../../hooks/usePost'
import Loading from '../common/Loading'
import { ModalProps } from '../common/Modal'
import { FixedSizeModal } from './BotConfigModal'
import NewBotDetails from './NewBotDetails'

const NewBotModal = ({ show, onClose }: ModalProps) => {
    const [addNewBot] = usePost<CreateBotPayload, CreateBotResponse>(botListEndpoint)
    const [botOptions] = useGet<NewBotOption[]>(newBotOptionsEndpoint)

    const { editBotConfig } = useBotContext()
    const [chosenBotId, setChosenBotId] = useState<string | null>(null)

    //chosenBotId might not be set on first load, so hand in botId here
    const handleCreate = async (class_name: string, name: string, description: string) => {
        if (class_name && name && description) {
            const newBot = await addNewBot({ className: class_name, name, description })
            if (newBot) {
                toast.success(`Successfully created, now editing ${name}`)
                editBotConfig(newBot)
            }
        } else {
            toast.warn('Please enter a name & description for the new bot')
        }
    }

    return (
        <FixedSizeModal show={show} onClose={onClose} heading='Create new tool'>
            {botOptions?.length ? (
                <NewBotDetails
                    key={chosenBotId}
                    botId={chosenBotId ?? botOptions.find(bo => bo.className === 'document_over_limit')?.className ?? botOptions[0].className}
                    botOptions={botOptions}
                    onOptionChange={setChosenBotId}
                    onCreate={handleCreate}
                />
            ) : (
                <Loading />
            )}
        </FixedSizeModal>
    )
}

export default NewBotModal
