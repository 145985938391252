import { CircularProgress, Typography } from '@mui/material'
import styled, { useTheme } from 'styled-components'
import { PromptLibrary } from './types'
import { useUserContext } from '../../context/UserContext'
import { usePromptContext } from '../../context/PromptContext'
import { useGet } from '../../hooks/useGet'
import { libraryExportEndpoint } from '../../endpoints'
import { ButtonTooltipWrapper, InnerTabButton } from './styles'
import EditIcon from '../../icons/EditIcon'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ShareIcon from '../../icons/ShareIcon'
import { canEditLibrary, canExportLibrary, canShareLibrary } from './permissions'

const Title = styled(Typography)`
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ShareButton = styled(InnerTabButton)`
    padding: 0;
`

interface LibraryTabProps {
    library: PromptLibrary
    isSelected: boolean
    onShare: (anchor: HTMLElement) => void
}

const LibraryTab = ({ library: { id, title, userRole }, isSelected, onShare }: LibraryTabProps) => {
    const { colors } = useTheme()
    const { isMaintainer } = useUserContext()
    const { onEditLibrary } = usePromptContext()

    const [, exportLoading, getLibraryExport] = useGet<Blob>(libraryExportEndpoint(id), {
        requestOnRender: false,
        responseType: 'blob',
    })

    return (
        <>
            <Title variant='h6'>{title}</Title>
            {isSelected && canEditLibrary(userRole) && (
                <ButtonTooltipWrapper title='Edit Library'>
                    <InnerTabButton onClick={() => onEditLibrary(true)} type='button' aria-label='Edit Library'>
                        <EditIcon color={colors.text} />
                    </InnerTabButton>
                </ButtonTooltipWrapper>
            )}
            {isSelected && canShareLibrary(isMaintainer, userRole) && (
                <ButtonTooltipWrapper title='Share Library'>
                    <ShareButton onClick={({ currentTarget }) => onShare(currentTarget)} type='button' aria-label='Share Library'>
                        <ShareIcon />
                    </ShareButton>
                </ButtonTooltipWrapper>
            )}
            {isSelected && canExportLibrary(isMaintainer, userRole) && (
                <ButtonTooltipWrapper title='Export Library'>
                    <InnerTabButton onClick={getLibraryExport} type='button' aria-label='Export Library' disabled={exportLoading}>
                        {exportLoading ? <CircularProgress size={15} /> : <CloudDownloadIcon htmlColor={colors.text} fontSize='small' />}
                    </InnerTabButton>
                </ButtonTooltipWrapper>
            )}
        </>
    )
}

export default LibraryTab
