import { styled, Typography, useTheme } from '@mui/material'
import { Report } from './types'
import Button from '../common/Button'
import FileIcon from '../../icons/FileIcon'
import { overflowText } from '../common/styles'
import ExportIcon from '../../icons/ExportIcon'
import { useDocumentDownload } from '../../hooks/useDocumentDownload'
import Loading from '../common/Loading'
import { Document, UUID } from '../../types'
import ErrorCard from '../common/ErrorCard'
import TransparentButton from '../common/TransparentButton'
import { useReportContext } from '../../context/ReportContext'

const Container = styled('div')(({ theme }) => ({
    padding: 16,
    backgroundColor: theme.palette.background.default,
    border: '1px solid #d8d8d8',
    borderRadius: 8,
}))

const OutputContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

const StyledFileIcon = styled(FileIcon)({
    marginRight: 5,
    width: 30,
    height: 30,
})

const Name = styled(Typography)({
    flex: 1,
    margin: 0,
    ...overflowText,
})

const InputContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
})

const LargeLoading = styled(Loading)({
    width: 38,
    height: 38,
    marginRight: 33,
})

const SmallLoading = styled(Loading)({
    width: 105,
})

const ExportButton = styled(TransparentButton)({
    marginRight: 30,
})

interface InputDocumentProps {
    chatId?: UUID
    document: Document
}

const InputDocument = ({ chatId, document: { id, state, name, error } }: InputDocumentProps) => {
    const [isDownloadable, download, loading] = useDocumentDownload(chatId, id, state)

    if (state === 'error') {
        return (
            <ErrorCard>
                Encountered errors with <i>{name}</i>, it was ignored for report generation. {error}
            </ErrorCard>
        )
    }

    return (
        <InputContainer>
            <Name>{name}</Name>
            {loading ? (
                <SmallLoading secondaryColor bar />
            ) : (
                <ExportButton onClick={download} disabled={!isDownloadable} aria-label={`download ${name}`}>
                    <ExportIcon />
                </ExportButton>
            )}
        </InputContainer>
    )
}

interface InputListProps {
    chatId: UUID
    sourceDocuments: Document[]
}

const InputList = ({ chatId, sourceDocuments }: InputListProps) => {
    const { palette } = useTheme()

    return (
        <div>
            <Typography sx={{ color: palette.text.secondary, margin: '5px 0', fontWeight: 'bold' }}>Input</Typography>
            {sourceDocuments.map(doc => (
                <InputDocument key={doc.id} chatId={chatId} document={doc} />
            ))}
        </div>
    )
}

interface ReportCardProps {
    className?: string
    report: Report
}

const ReportCard = ({ className, report }: ReportCardProps) => {
    const { currentChat } = useReportContext()

    const [isDownloadable, download, loading] = useDocumentDownload(currentChat.id, report.report?.id, report.report?.state)

    switch (report.state) {
        case 'completed':
            return (
                <Container className={className}>
                    <OutputContainer>
                        <StyledFileIcon />
                        <Name>{report.report.name}</Name>
                        {loading ? (
                            <LargeLoading secondaryColor />
                        ) : (
                            <Button onClick={download} disabled={!isDownloadable} aria-label={`download ${report.report.name}`}>
                                Download
                            </Button>
                        )}
                    </OutputContainer>
                    <InputList chatId={currentChat.id} sourceDocuments={report.sourceDocuments} />
                </Container>
            )
        case 'error':
            return (
                <Container className={className}>
                    <ErrorCard>Failed to generate report.</ErrorCard>
                    <InputList chatId={currentChat.id} sourceDocuments={report.sourceDocuments} />
                </Container>
            )
        default:
            return null
    }
}

export default ReportCard
